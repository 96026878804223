import { Component, OnInit, Input, SecurityContext } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { NgxSpinnerService } from "ngx-spinner";
import swal from 'sweetalert2'
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  public location = {
    name: 'LEDU Oficina Principal',

  }
  public mailData: any = {}
  public formValidated: boolean = true
  public objectStyles
  public formImage = 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fcontact%2Fcontacto.jpg?alt=media&token=1f5b299e-5748-4abd-9a1c-e237dc4cff8f'

  constructor(private http: HttpClient, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer) { }

  getImageStyles() {
    let url = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(this.formImage))
    let imageStyles = `url(${url})`;
    this.objectStyles =  { 'background-image': imageStyles, 'background-position': `50% 50%` }
    return this.sanitizer.sanitize(SecurityContext.STYLE, this.sanitizer.bypassSecurityTrustStyle(this.objectStyles))
  }

  sendMail() {
    this.spinner.show();
    this.mailData.subsidiary = this.location.name
    this.mailData.type = 'contact'
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    this.http.post(' https://us-central1-ledu-web-page.cloudfunctions.net/sendNotificationMail', this.mailData, httpOptions)
      .subscribe((res: any) => {
        console.log('MAIL SENT')
        console.log(res)
        this.spinner.hide();
        this.mailData = {}
        swal.fire({
          icon: 'success',
          title: '¡Mensaje Enviado!',
          text: 'Revisaremos tu mensaje y te contactaremos lo antes posible.',
          showConfirmButton: false,
          timer: 2000
        })
      },
      (err) => {
        console.log(err)
        this.mailData = {}
      })
  }
  checkMailData() {
    if(this.mailData.name && this.mailData.mail && this.mailData.phone && this.mailData.clientSubject && this.mailData.client) {
      this.formValidated = true
    } else {
      this.formValidated = false
    }

  }

  checkMailDataButton() {
    if(this.mailData.name && (this.mailData.mail && this.validateEmail(this.mailData.mail)) && this.mailData.phone && this.mailData.clientSubject && this.mailData.client) {
      return true
    } else {
      return false
    }
  }

  validateEmail(email) {
    if(email) {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    }
  }

  ngOnInit() {
  }

}
