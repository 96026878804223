import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FirestoreService } from '../services/firestore/firestore.service'
import { FilesUploadService } from '../services/firestore/files-upload.service'
import { AuthService } from '../services/core/auth.service';
import { NgxSpinnerService } from "ngx-spinner";
import swal from 'sweetalert2'
import * as moment from 'moment';
import * as _ from 'lodash'

@Component({
  selector: 'blog-new',
  templateUrl: './blog-new.component.html',
  styleUrls: ['./blog-new.component.scss']
})
export class BlogNewComponent implements OnInit {
  @Input() type
  @Input() data
  @Output() getViewChange = new EventEmitter<any>();

  public blog: any = {}

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  public isHovering: boolean
  public image: any
  public previewURL
  public alreadyImage: boolean = false

  public files: any = [];
  public thumbnails: any = []
  public spaceFilesToUpload: any
  public canUploadFiles: boolean= true
  public totalSize:number = 5242880
  public uploading: boolean = false
  public showUploadFiles:boolean = false

  constructor(
    private firestoreService: FirestoreService,
    private filesUploadService: FilesUploadService,
    private authService:AuthService,
    private spinner: NgxSpinnerService
  ) { }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  removeImage() {
    this.image = null;
    this.previewURL = null;
  }

  cancelAddFiles() {
    this.showUploadFiles = false
    this.thumbnails = []
     this. files = []
  }

  setFile(event: FileList) {
    this.image = event;
    var reader = new FileReader();
    reader.readAsDataURL(event.item(0));
    reader.onload = (e) => {
      this.previewURL = reader.result;
    }
  }

  newPhoto() {
    this.alreadyImage = false
  }

  removeFiles() {
    this.files = [];
    this.thumbnails = [];
  }

  removeHtmlTags(str: string): string {
    return str.replace(/<[^>]*>/g, '');
  }

  limitCharacters(str: string): string {
    return str.slice(0, 60);
  }

  fixString(str: string): string {
    let replacedStr = str.replace(/\s+/g, '-');
    replacedStr = replacedStr.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    replacedStr = replacedStr.replace(/[^\w\s-]/g, '');
    replacedStr = replacedStr.toLowerCase()
    return replacedStr;
  }

  getBlogURL(title) {
    const plainString = this.removeHtmlTags(title);
    let shortString = this.limitCharacters(plainString);
    return this.fixString(shortString)
  }

  saveBlog() {
    this.uploading = true;
    this.spinner.show();

    this.authService.getCurrentUserData()
    .then(user => {
      if(user) {
        this.filesUploadService.uploadFile(this.image, `blogs`, true).then(downloadURL => {
          this.blog.mainImage = downloadURL
          this.blog.created_at = moment.now()
          this.blog.created_by =  user.$id
          this.blog.urlTitle = this.getBlogURL(this.blog.title)
          this.firestoreService.create(this.blog, 'blogs').then(_ => {
            swal.fire({
              icon: 'success',
              title: "¡Felicidades!",
              text: `El artículo se creó con éxito`,
              timer: 1500,
              showConfirmButton: false,
            });
            this.uploading = false;
            this.spinner.hide();
            this.getViewChange.emit({view: 'main', data: ''})
          })
        })
      }
    })

  }

  updateProcess() {
    this.uploading = true;
    this.spinner.show();
    if(this.image) {
      this.uploadNewImage()
    } else {
      this.updateData()
    }
  }

  saveChanges() {
    this.updateProcess()
  }

  updateData() {
    let id = this.blog.$id
    delete this.blog['$id'];
    this.firestoreService.update(id, this.blog, 'blogs').then(_ => {
      swal.fire({
        icon: 'success',
        title: "¡Felicidades!",
        text: `Los datos de actualizaron con éxito`,
        timer: 1500,
        showConfirmButton: false,
      });
      this.uploading = false;
      this.spinner.hide();
      this.getViewChange.emit({view: 'main', data: ''})
    })
  }

  uploadNewImage() {
    this.filesUploadService.uploadFile(this.image, `blogs`, true).then(downloadURL => {
      this.blog.mainImage = downloadURL
      this.updateData()
    })
  }

  deleteBlog() {
    swal.fire({
      title: 'Eliminar Artículo',
      text: '¿Quieres eliminar este artículo?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `Sí`,
      confirmButtonColor: '#EB0404',
      denyButtonText: `No`,
      denyButtonColor: '#807E7E',
    }).then((result) => {
      if (result.isConfirmed) {
        let id = this.blog.$id
        this.firestoreService.delete(id, 'blogs').then(_ => {
          swal.fire({
            icon: 'success',
            title: "¡Felicidades!",
            text: `El artículo de eliminó con éxito`,
            timer: 1500,
            showConfirmButton: false,
          })
          this.cancel()
        })
      }
    })
  }

  cancel() {
    this.getViewChange.emit({view: 'main', data: ''})
  }

  ngOnInit() {
    if(this.type === 'edit') {
      this.blog = {...this.data}
      this.alreadyImage = true
    }
  }

}
