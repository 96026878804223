import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ledu-user-consult',
  templateUrl: './ledu-user-consult.component.html',
  styleUrls: ['./ledu-user-consult.component.scss']
})
export class LeduUserConsultComponent implements OnInit {

  public img = 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fusers%2Fconsultas.png?alt=media&token=27752aed-dc63-46c1-8af6-f97df25012c0'

  public saiECommerceURL
  constructor() {
    this.saiECommerceURL = environment.saiECommerceURL
  }

  ngOnInit() {
  }

}
