import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { NgxSpinnerService } from "ngx-spinner";
import { trigger, state, style, animate, transition } from '@angular/animations';
import swal from 'sweetalert2'

declare const window: any;
declare var gtag;

@Component({
  selector: 'ledu-user-mon-data',
  templateUrl: './ledu-user-mon-data.component.html',
  styleUrls: ['./ledu-user-mon-data.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class LeduUserMonDataComponent implements OnInit {

  public toCheck: any = {}
  public correct: boolean
  public userPhones: any
  public showUserData: boolean = false
  public showUserPhones: boolean = false
  public step = 1
  public newPhone: any = { name: '', number: '' }
  public mailData: any = {
    name: '',
    mail: '',
    phone: '',
    id: '',
    cpi: '',
    toUpdate: {
      name: '',
      cpi: '',
      mail: '',
      phone: '',
      phones: []
    }
  }

  public img = 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fusers%2Fdatos.png?alt=media&token=8cb06f9f-f3c1-4018-960a-e7f1617cab89'

  constructor(private http: HttpClient, private spinner: NgxSpinnerService) { }

  nextStep() {
    this.step = this.step + 1
  }

  checkStep1() {
    if (this.mailData.name && ((this.mailData.mail && this.validateEmail(this.mailData.mail)) || this.mailData.phone)) {
      return true
    } else {
      return false
    }
  }

  checkStep2() {
    if (this.mailData.id || this.mailData.secretWord || this.mailData.address) {
      return true
    } else {
      return false
    }
  }

  addNewPhone(phone) {
    this.mailData.toUpdate.phones.push(phone)
    this.newPhone = { name: '', number: '' }
  }

  deletePhone(phone) {
    let index = this.mailData.toUpdate.phones.indexOf(phone)
    this.mailData.toUpdate.phones.splice(index, 1);
  }

  editPhone(phone) {
    phone.showEdit = true
  }

  savePhone(phone) {
    phone.showEdit = false
  }

  movePhone(phone, to) {
    const fromIndex = this.mailData.toUpdate.phones.indexOf(phone)
    if ((fromIndex === 0 && to === 'up') || (fromIndex === this.mailData.toUpdate.phones.length && to === 'down')) {
      return
    }
    const toIndex = to === 'up' ? fromIndex - 1 : fromIndex + 1
    this.mailData.toUpdate.phones.splice(toIndex, 0, this.mailData.toUpdate.phones.splice(fromIndex, 1)[0])
  }

  sendMail() {
    this.spinner.show();
    this.mailData.type = 'monitoring-update'
    console.log(this.mailData)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    this.http.post(' https://us-central1-ledu-web-page.cloudfunctions.net/sendNotificationMail', this.mailData, httpOptions)
      .subscribe((res: any) => {
        console.log('MAIL SENT')
        console.log(res)
        this.spinner.hide();
        this.resetData()
        swal.fire({
          icon: 'success',
          title: '¡Mensaje Enviado!',
          text: 'Revisaremos tu mensaje y actualizaremos los datos solicitados.',
          showConfirmButton: false,
          timer: 2000
        })
      },
        (err) => {
          console.log(err)
          this.spinner.hide();
          this.resetData()
        })
  }

  validateEmail(email) {
    if (email) {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    }
  }

  openWhatsapp() {
    let link = 'https://api.whatsapp.com/send?phone=524493017967&text=Hola!%20Quiero%20actualizar%20los%20datos%20de%20mi%20cuenta%20de%20monitoreo'
    window.open(link, '_blank').focus();
    // Adwords Conversion
    gtag('event', 'conversion', {
      'send_to': 'AW-635950918/dxKdCOS5xIAYEMaun68C'
    });
  }

  resetData() {
    this.mailData = {
      name: '',
      mail: '',
      phone: '',
      id: '',
      cpi: '',
      toUpdate: {
        name: '',
        cpi: '',
        mail: '',
        phone: '',
        phones: []
      }
    }
    this.toCheck = {}
    this.showUserData = false
    this.showUserData = false
    this.showUserPhones = false
    this.step = 1
  }


  ngOnInit() {
  }

}
