import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'about-us-our-team',
  templateUrl: './about-us-our-team.component.html',
  styleUrls: ['./about-us-our-team.component.scss']
})
export class AboutUsOurTeamComponent implements OnInit {

  public employees = [
    {name: 'Araceli', lastname:'Delgado', job: 'Gerente Administración', photo: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2FEquipo%20Equipo%20Araceli.jpg?alt=media&token=2fc79684-a3f6-4e02-9741-a285868a042e'},
    {name: 'Juan Pablo', lastname:'de la Cruz', job: 'Gerente Operaciones', photo: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2FEquipo%20Juan_Pablo.jpg?alt=media&token=9b5b26d3-ce9e-48cf-8c1b-314c5c729f62'},
    {name: 'Victor', lastname:'Gutiérrez', job: 'Jefe de Servicios', photo: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2FEquipo%20Victor.jpg?alt=media&token=eda5a278-b3fb-4a1c-9408-55f1221932db'},
    {name: 'Eloy', lastname:'González', job: 'Jefe de Sistemas', photo: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2FEquipo%20Eloy.jpg?alt=media&token=c89befd3-dc21-467b-bda9-c02c32442e82'},
    {name: 'Luis Alberto', lastname:'Mora', job: 'Jefe de Instalaciones', photo: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2FEquipo%20Luis%20Alberto.jpg?alt=media&token=bb0fd945-ff94-4d6d-bf34-f3eb04bfa1ed'},
    {name: 'Diego ', lastname:'Hernández', job: 'Jefe de Inventarios', photo: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2FEquipo%20Diego.jpg?alt=media&token=4592868b-77e1-47d3-a2e6-fb917d2dc82f'},
    {name: 'Krish', lastname:'Malo', job: 'Jefe de Industriales', photo: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2FEquipo%20Krish.jpg?alt=media&token=cb1083d1-35f5-41be-ad2d-dc397a39dcf8'},
    {name: 'Juan', lastname:'Esquivel', job: 'Jefe de Monitoreo', photo: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2FEquipo%20Juan.jpg?alt=media&token=fec78671-7bbe-40af-9e18-5d147dc73764'},
  ]

  public splideOptions = { 
    type: 'loop', 
    perPage: 6, 
    keyboard: false,
    autoplay: true,
    speed: 2000,
    interval: 2500,
    perMove: 1,
    arrows: false,
    pagination: false,
    breakpoints: {
      575.98: {
        perPage: 2,
      },
      767.98: {
        perPage: 3,
      },
      991: {
        perPage: 3,
      },
      1198: {
        perPage: 3,
      },
      1715: {
        perPage: 4,
      },
      2000: {
        perPage: 5,
      },
    }
  }

  constructor() { }


  getImageStyles(image) {
    let url = `url(${image})`;
    return { 'background-image': url, 'background-position': `50% 50%` }
  }

  ngOnInit() {
  }

}
