import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { FirestoreService } from '../services/firestore/firestore.service'
import { FilesUploadService } from '../services/firestore/files-upload.service'
import { NgxSpinnerService } from "ngx-spinner";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import swal from 'sweetalert2'
import * as moment from 'moment';
import * as _ from 'lodash'


@Component({
  selector: 'sm-product-new',
  templateUrl: './sm-product-new.component.html',
  styleUrls: ['./sm-product-new.component.scss']
})
export class SmProductNewComponent implements OnInit {

  @Input() type
  @Input() data
  @Output() getViewChange = new EventEmitter<any>();

  public product: any = {
    sale: false,
    tags: []
  }

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public isHovering: boolean
  public image: any
  public previewURL
  public alreadyImage: boolean = false

  public files: any = [];
  public thumbnails: any = []
  public spaceFilesToUpload: any
  public canUploadFiles: boolean= true
  public totalSize:number = 5242880
  public uploading: boolean = false
  public showUploadFiles:boolean = false

  constructor(
    private firestoreService: FirestoreService,
    private filesUploadService: FilesUploadService,
    private spinner: NgxSpinnerService
  ) { }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.product.tags.push(value.trim())
    }
    if (input) {
      input.value = '';
    }
  }

  remove(tag): void {
    const index = this.product.tags.indexOf(tag)

    if (index >= 0) {
      this.product.tags.splice(index, 1)
    }
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  removeImage() {
    this.image = null;
    this.previewURL = null;
  }

  cancelAddFiles() {
    this.showUploadFiles = false
    this.thumbnails = []
     this. files = []
  }

  uploadOtherImages(callbackUpdate?) {
    let updates = {
      otherImages: this.product.otherImages || []
    }
    this.spinner.show();
    let promises = [];
    for (var i = 0; i < this.files.length; i++) {
      let file = this.files[i]
      promises.push(this.filesUploadService.uploadMultipleFiles(file, 'products'))
    }
    Promise.all(promises).then(downloadURLs => {
      if(!updates.otherImages.length) {
        updates.otherImages = downloadURLs
      } else {
        updates.otherImages = updates.otherImages.concat(downloadURLs)
      }
      this.firestoreService.update(this.product.$id, updates, 'products').then(_ => {
        this.files = []
        this.thumbnails = []
        this.showUploadFiles = false
        this.product.otherImages = updates.otherImages
        if(callbackUpdate) {
          this.updateProcess()
        } else {
          swal.fire({
            icon: 'success',
            title: "¡Felicidades!",
            text: `Las imagenes se subieron con éxito`,
            timer: 1500,
            showConfirmButton: false,
          })
        }
      })
    })
  }

  setFile(event: FileList) {
    this.image = event;
    var reader = new FileReader();
    reader.readAsDataURL(event.item(0));
    reader.onload = (e) => {
      this.previewURL = reader.result;
    }
  }

  newPhoto() {
    this.alreadyImage = false
  }

  removeFiles() {
    this.files = [];
    this.thumbnails = [];
  }

  getRandomId(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  setFiles(event: FileList) {
    let filesToUploadTotal
    if (this.product.otherImages) {
      filesToUploadTotal = _.sumBy<any>(this.product.otherImages, f => {
        return f.size;
      })
    } else {
      filesToUploadTotal = 0
    }
    let addEvent = false;
    let newFileListArray = [];

    if (!this.files.length) {
      this.files = Array.from(event);
    } else {
      addEvent = true;
      newFileListArray = Array.from(event);
    }

    let filesToRemove = this.files.filter(f => {
      return !f.name.includes('.')
    })

    filesToRemove.forEach(f => {
      let pos = this.files.indexOf(f)
      this.files.splice(pos, 1);
    });

    if (!addEvent) {
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i]
        let id = this.getRandomId(10)
        this.files[i].id = id
        let fileObject = {
          id,
          name: file.name,
          ext: file.name.split('.').pop().toLowerCase(),
          preview: null,
          size: file.size
        }
        if (file.type.includes('image')) {
          var reader = new FileReader();
          reader.readAsDataURL(event.item(i));
          reader.onload = (e) => {
            let target: any = e.target
            fileObject.preview = target.result;
            this.thumbnails.push(fileObject)
          }
        } else {
          this.thumbnails.push(fileObject)
        }
      }
    } else {
      for (var i = 0; i < newFileListArray.length; i++) {
        let file = newFileListArray[i]
        let id = this.getRandomId(10)
        file.id = id
        this.files.push(file)
        let fileObject = {
          id,
          name: file.name,
          ext: file.name.split('.').pop().toLowerCase(),
          preview: null,
          size: file.size
        }
        if (file.type.includes('image')) {
          var reader = new FileReader();
          reader.readAsDataURL(event.item(i));
          reader.onload = (e) => {
            let target: any = e.target
            fileObject.preview = target.result;
            this.thumbnails.push(fileObject)
          }
        } else {
          this.thumbnails.push(fileObject)
        }
      }
    }
    filesToUploadTotal = filesToUploadTotal + _.sumBy<any>(this.files, f => {
      return f.size;
    })
    this.spaceFilesToUpload = filesToUploadTotal;

    if (filesToUploadTotal > this.totalSize) {
      swal.fire({
        icon: 'warning',
        title: "¡Advertencia!",
        text: `Espacio total exedido. No puedes subir todos los archivos seleccionados (Maximo 5MB)`,
        timer: 2500,
        showConfirmButton: false,
      });
      this.canUploadFiles = false;
    } else {
      this.canUploadFiles = true;
    }
  }

  removeFileToUpload(file) {
    let filesToUploadTotal
    if (this.product.otherImages) {
      filesToUploadTotal = _.sumBy<any>(this.product.otherImages, f => {
        return f.size;
      })
    } else {
      filesToUploadTotal = 0
    }
    let pos = this.thumbnails.indexOf(file)
    let fileInFiles = this.files.find(f => {
      return f.id === file.id
    })
    let pos2 = this.files.indexOf(fileInFiles)
    this.files.splice(pos2, 1);
    this.thumbnails.splice(pos, 1);
    filesToUploadTotal = filesToUploadTotal + _.sumBy<any>(this.files, f => {
      return f.size;
    })
    this.spaceFilesToUpload = filesToUploadTotal;
    if ((filesToUploadTotal) > this.totalSize) {
      this.canUploadFiles = false;
    } else {
      this.canUploadFiles = true;
    }
  }

  getKB(bytes) {
    let res = Number(bytes) / 1024;
    if (res >= 1024) {
      res = res / 1024
      return res.toFixed(2) + ' MB'
    }
    return res.toFixed(2) + ' KB'
  }

  removeOtherImages(image) {
    let index = this.product.otherImages.indexOf(image)
    this.product.otherImages.splice(index, 1)
  }

  saveProduct() {
    this.uploading = true;
    this.spinner.show();
    let promises = [];
    for (var i = 0; i < this.files.length; i++) {
      let file = this.files[i]
      promises.push(this.filesUploadService.uploadMultipleFiles(file, 'products'))
    }
    Promise.all(promises).then(downloadURLs => {
      this.product.otherImages = downloadURLs
      this.filesUploadService.uploadFile(this.image, `products`, true).then(downloadURL => {
        this.product.mainImage = downloadURL
        this.product.created_at = moment.now()
        this.firestoreService.create(this.product, 'products').then(_ => {
          swal.fire({
            icon: 'success',
            title: "¡Felicidades!",
            text: `El producto se creó con éxito`,
            timer: 1500,
            showConfirmButton: false,
          });
          this.uploading = false;
          this.spinner.hide();
          this.getViewChange.emit({view: 'main', data: ''})
        })
      })
    })
  }

  updateProcess() {
    this.uploading = true;
    this.spinner.show();
    if(this.image) {
      this.uploadNewImage()
    } else {
      this.updateData()
    }
  }

  saveChanges() {
    if(this.files.length) {
      swal.fire({
        title: 'Dejaste imagenes sin subir' ,
        text: '¿Quieres agregar estas imagenes al producto?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Sí`,
        confirmButtonColor: '#75bc1e',
        denyButtonText: `No`,
        denyButtonColor: '#c01e1e',
      }).then((result) => {
        if (result.isConfirmed) {
          this.uploadOtherImages(true)
        } else if (result.isDenied) {
          this.updateProcess()
        }
      })
    } else {
      this.updateProcess()
    }
  }

  updateData() {
    let id = this.product.$id
    delete this.product['$id'];
    this.firestoreService.update(id, this.product, 'products').then(_ => {
      swal.fire({
        icon: 'success',
        title: "¡Felicidades!",
        text: `Los datos de actualizaron con éxito`,
        timer: 1500,
        showConfirmButton: false,
      });
      this.uploading = false;
      this.spinner.hide();
      this.getViewChange.emit({view: 'main', data: ''})
    })
  }

  uploadNewImage() {
    this.filesUploadService.uploadFile(this.image, `products`, true).then(downloadURL => {
      this.product.mainImage = downloadURL
      this.updateData()
    })
  }

  cancel() {
    this.getViewChange.emit({view: 'main', data: ''})
  }

  ngOnInit() {
    if(this.type === 'edit') {
      this.product = {...this.data}
      this.alreadyImage = true
    }
  }

}
