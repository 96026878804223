import { Component, OnInit, Input, Output, SecurityContext, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FirestoreService } from '../services/firestore/firestore.service';
import swal from 'sweetalert2'
import { MainService } from '../services/core/main.service';

@Component({
  selector: 'product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input() product
  @Input() type
  @Output() getViewChange = new EventEmitter<any>();

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private firestoreService: FirestoreService,
    private mainService: MainService
  ) { }

  getImageStyles(image) {
    let url = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(image))
    let imageStyles = `url(${url})`;
    let objectStyles: any = { 'background-image': imageStyles, 'background-position': `50% 50%` }
    return this.sanitizer.sanitize(SecurityContext.STYLE, this.sanitizer.bypassSecurityTrustStyle(objectStyles))
  }
  openProduct(): void {
    this.router.navigate(['/producto', this.product.$id]);
  }
  editProduct() {
    this.getViewChange.emit({ view: 'edit', data: this.product.$id })
  }
  addToCart() {
    this.mainService.addToCart({$id: this.product.$id, quantity: 1})
  }
  deleteProduct() {
    swal.fire({
      title: 'Eliminar Producto',
      text: '¿Quieres deshabilitar este producto (ya no aparecera en las listas de productos)?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `Sí`,
      confirmButtonColor: '#75bc1e',
      denyButtonText: `No`,
      denyButtonColor: '#c01e1e',
    }).then((result) => {
      if (result.isConfirmed) {
        let id = this.product.$id
        let updates = {
          disabled: true
        }
        this.firestoreService.update(id, updates, 'products').then(_ => {
          swal.fire({
            icon: 'success',
            title: "¡Felicidades!",
            text: `El producto de deshabilito con éxito`,
            timer: 1500,
            showConfirmButton: false,
          });
        })
      }
    })
  }

  ngOnInit() {
  }

}
