import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(
    private firestore: AngularFirestore,
    ) { }

    create(data, route) {
      return this.firestore.collection(route).add(data);
    }  

    get(documentId, route) {
      return this.firestore.collection(route).doc(documentId).get();
    } 

    delete(documentId, route) {
      return this.firestore.collection(route).doc(documentId).delete();
    } 

    getCollection(route) {
      return this.firestore.collection(route).snapshotChanges();
    }  

    update(documentId, data, route) {
      return this.firestore.collection(route).doc(documentId).update(data);
    }
}
