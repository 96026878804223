import { Component, OnInit } from '@angular/core';
import { NgxCarousel } from 'ngx-carousel';

@Component({
  selector: 'our-clients',
  templateUrl: './our-clients.component.html',
  styleUrls: ['./our-clients.component.scss']
})
export class OurClientsComponent implements OnInit {

  public clients = [
    { name: "Grupo San Cristóbal", page: "gruposancristobal.com.mx", image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fclients-logos%2Fgrupo%20san%20cristobal%201.png?alt=media&token=1e753a3d-8fb6-4a7b-acc9-10baf93f4ce5' },
    { name: "Jatco", page: "jatco.com.mx", image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fvarious%2FJatco-logo-01.png?alt=media&token=f54a3bc3-f513-4d99-881e-dfa0362935cd' },
    { name: "Yokohama", page: "yokohamallantas.com", image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fclients-logos%2Fimage%202.png?alt=media&token=e43b6fdb-0571-4b64-953d-be6588a4f4d3' },
    { name: "Lala", page: "lala.com.mx", image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fclients-logos%2Fimage%203.png?alt=media&token=7d3658d0-0e61-4831-a40e-c1ebd75d3d62' },
    { name: "Farmacias Similares", page: "farmaciasdesimilares.com", image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fclients-logos%2Fstore_type_1657053639826.png?alt=media&token=e0de72e8-9213-469a-999f-66b059afdb1a' },
  ]

  public splideOptions = { 
    type: 'loop', 
    perPage: 4, 
    keyboard: false,
    autoplay: true,
    speed: 2000,
    interval: 2500,
    perMove: 1,
    arrows: false,
    pagination: false,
    breakpoints: {
      575.98: {
        perPage: 1,
      },
      767.98: {
        perPage: 3,
      },
    }
  }

  constructor() {
  }

  getImageStyles(image) {
    let url = `url(${image})`;
    return { 'background-image': url, 'background-position': `50% 50%` }
  }

  ngOnInit() {
  }

}
