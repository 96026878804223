import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MainService } from '../services/core/main.service';
import { FirestoreService } from '../services/firestore/firestore.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Title, Meta } from '@angular/platform-browser';
import * as _ from 'lodash';

@Component({
  selector: 'app-blog-article',
  templateUrl: './blog-article.component.html',
  styleUrls: ['./blog-article.component.scss']
})
export class BlogArticleComponent implements OnInit {

  private routeSub: Subscription
  public blog: any = {}
  public blogs: any = []
  public images = []
  public dataReady = false
  public previousBlog = ''
  public nextBlog = ''
  public randomBlogs = []

  constructor(
    private route: ActivatedRoute,
    private firestoreService: FirestoreService,
    private spinner: NgxSpinnerService,
    private mainService: MainService,
    private titleService: Title,
    private metaService: Meta,
  ) { }

  getTitle(title: string): string | null {
    const regex = /<h1>(.*?)<\/h1>/;
    const match = title.match(regex);
    return match ? match[1] : null;
  }

  removeHtmlTags(str: string): string {
    return str.replace(/<[^>]*>/g, '');
  }

  limitCharacters(str: string): string {
    return str.slice(0, 180);
  }

  getBlogSummary(body) {
    const plainString = this.removeHtmlTags(body);
    let shortString = this.limitCharacters(plainString);
    shortString = shortString.concat('...');
    return shortString
  }

  getRandomBlogs(blogs: any[], currentId): any[] {
    let randomBlogs = []
    let filteredBlogs

    filteredBlogs = blogs.filter(b => {
      return b.$id !== currentId
    })

    if (filteredBlogs.length < 3) {
      return filteredBlogs
    }
  
    while (randomBlogs.length < 3) {
      const randomIndex = Math.floor(Math.random() * filteredBlogs.length);
      const randomElement = filteredBlogs[randomIndex];
  
      if (!randomBlogs.includes(randomElement)) {
        randomBlogs.push(randomElement);
      }
    }
  
    return randomBlogs
  }

  ngOnInit() {
    window.scrollTo(0, 0) 
    this.mainService.toggleSolidTopbar(false)
    this.spinner.show()
    this.routeSub = this.route.params.subscribe(params => {

      this.firestoreService.getCollection('blogs').subscribe(snapshot => {
        this.blogs = []
        snapshot.forEach((data: any) => {
          let user = data.payload.doc.data()
          user.$id = data.payload.doc.id
          this.blogs.push(user)
        })
        this.blogs = _.orderBy<any>(this.blogs, 'created_at', 'desc')
        let blogIndex
        this.blog = this.blogs.find((b, index) => {
          if(b.urlTitle === params.id) {
            blogIndex = index
            return b
          }
        })
        if(blogIndex > 0) {
          this.previousBlog = this.blogs[blogIndex-1].urlTitle
        } else {
          this.previousBlog = ''
        }
        if(blogIndex < (this.blogs.length-1)) {
          this.nextBlog = this.blogs[blogIndex+1].urlTitle
        } else {
          this.nextBlog = ''
        }

        this.randomBlogs = this.getRandomBlogs(this.blogs, this.blog.$id)

        let title = this.getTitle(this.blog.title)
        this.titleService.setTitle(`${title} - LEDU Vidas Tranquilas`);
        this.blog.onlyTitle = title
        let summary = this.getBlogSummary(this.blog.body)
        this.metaService.addTags([
          { name: 'description', content: summary },
          { name: 'image', content: this.blog.mainImage },
          { name: 'type', content: 'article' },
        ])
        this.blog.summary = summary
        this.images = [{
          url: this.blog.mainImage,
          title: '',
        }]
        this.firestoreService.get(this.blog.created_by, 'users').subscribe(doc => {
          let author = doc.data()
          this.blog.author = `${author.name} ${author.lastName}`
          this.dataReady = true
          this.spinner.hide()
        })
      })
    })
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

}
