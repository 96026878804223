import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {

  public departments = [
    { icon: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fcontact%2Fventas.png?alt=media&token=38efc198-3b38-457a-9e8b-f9626a5a7d15', name: 'Ventas', mail: 'ventas@depsaledu.mx'},
    { icon: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fcontact%2Finstalaciones.png?alt=media&token=f01a1651-0a2a-40a0-8aa2-14366e0cad39', name: 'Instalaciones', mail: 'instalaciones@depsaledu.mx'},
    { icon: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fcontact%2Fmonitoreo.png?alt=media&token=01149f2e-d6f0-45de-b478-5c7144ba844b', name: 'Monitoreo', mail: 'central@depsaledu.mx'},
    { icon: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fcontact%2FServicios.png?alt=media&token=11a7b8ab-1f8e-443c-9816-a15a043cbf80', name: 'Servicios', mail: 'aux_servicios@depsaledu.mx'},
    { icon: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fcontact%2Fpagos.png?alt=media&token=31c43248-fbaa-4b2e-94dd-a8eebb5ba2ed', name: 'Pagos y facturación', mail: 'cobranza@depsaledu.mx'},
    { icon: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fcontact%2Ftelefono%20l.png?alt=media&token=51bfda75-ef93-4a99-bfc9-db372b93098c', name: '', mail: '449 910 9930'},
  ]

  public electrificados = [
    { icon: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fcontact%2Fcorreo.png?alt=media&token=675f4610-3342-4efa-ae90-a368de2415b1', name: 'General', mail: 'ledu.electrificados@gmail.com'},
    { icon: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fcontact%2Ftelefono%20s-2.png?alt=media&token=94e52ad6-fc32-48dc-acf8-0901cc75f6e1', name: '', mail: '449 994 9490'},
  ]

  public locationIcon = 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fcontact%2Fubicacio%CC%81n%20s.png?alt=media&token=70caec87-711a-4a2d-ba18-b4243a1a72d1'

  constructor() { }

  ngOnInit() {
  }

}
