import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'home-solutions',
  templateUrl: './home-solutions.component.html',
  styleUrls: ['./home-solutions.component.scss']
})
export class HomeSolutionsComponent implements OnInit {

  public objectStyles
  public urls = [
    'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fvarious%2Fmonitoreo_Ledu.png?alt=media&token=192b02f2-1d1b-452f-8e57-cec6cf761388',
    'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fvarious%2Fvideovigilancia%20ledu.png?alt=media&token=8897dbfe-4892-4550-b318-5b416ee9678b',
    'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fvarious%2FDefault.png?alt=media&token=40419395-63e7-46e9-80be-ab4fd1ded795'
  ]

  constructor(private sanitizer: DomSanitizer) { }

  getImageStyles(image) {
    let url = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(image))
    let imageStyles = `url(${url})`;
    this.objectStyles =  { 'background-image': imageStyles, 'background-position': `50% 50%` }
    return this.sanitizer.sanitize(SecurityContext.STYLE, this.sanitizer.bypassSecurityTrustStyle(this.objectStyles))
  }

  ngOnInit() {
  }

}
