import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'sm-product-main',
  templateUrl: './sm-product-main.component.html',
  styleUrls: ['./sm-product-main.component.scss']
})
export class SmProductMainComponent implements OnInit {

  @Input() products
  @Output() getViewChange = new EventEmitter<any>();
  private _currentViewSub
  
  public get currentViewSub() : any {
    return this._currentViewSub
  }
  
  public set currentViewSub(v : any) {
    this._currentViewSub = v
    this.getViewChange.emit(v)
  }

  public filteredProducts
  public filters: any = {
    stringToSearch: '',
    orderBy: {}
  }
  public options = {
    orderBy: [
      {key: 'name', name:'Nombre', icon: 'fas fa-box'},
      {key: 'price', name:'Precio', icon: 'fas fa-dollar-sign'},
      {key: 'created_at', name:'Fecha de Creación', icon: 'fas fa-calendar'}
    ]
  }

  constructor() { }

  setSelected(selected, type) {
    this.filters[type] = selected;
    this.applyFilters()
  }

  newProduct() {
    this.getViewChange.emit({view: 'new', data: ''})
  }

  applyFilters() {
    let tempArray = this.products

    //BY STRING
    if (this.filters.stringToSearch !== '') {
      tempArray = tempArray.filter(e => {
        let lowercase = this.filters.stringToSearch.toLowerCase();
        let elementData = e.name
        return elementData.toLowerCase().includes(lowercase);
      })
    }
    //ORDER BY
    if (tempArray.length) {
      tempArray = _.orderBy<any>(tempArray, this.filters.orderBy.key, 'asc')
    }

    this.filteredProducts = tempArray;
  }

  ngOnInit() {
    this.filters.orderBy = this.options.orderBy[0]
    this.applyFilters()
  }

  ngOnChanges(changes:any) {
    this.applyFilters()
  }

}
