import { Component, OnInit, Input, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'blog-main-header',
  templateUrl: './blog-main-header.component.html',
  styleUrls: ['./blog-main-header.component.scss']
})
export class BlogMainHeaderComponent implements OnInit {

  @Input() blogs
  public show = true
  public objectStyles
  public gradient = true
  public displayLimit = 3
  public blogsToShow

  constructor(
    private carouselConfig: NgbCarouselConfig,
    private sanitizer: DomSanitizer,
    private router: Router,
  ) {
    carouselConfig.interval = 5000;
    carouselConfig.wrap = true;
    carouselConfig.keyboard = true;
  }

  getImageStyles(image) {
    let style
    style = this.gradient ? "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 40%, rgba(12,37,129,0.9) 100%), " : ""
    let url = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(image))
    let gradient = `${style}url(${url})`
    this.objectStyles = { 'background-image': gradient, 'background-position': `50% 50%` }
    return this.sanitizer.sanitize(SecurityContext.STYLE, this.sanitizer.bypassSecurityTrustStyle(this.objectStyles))
  }

  getImageStylesBlog(image) {
    let url = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(image))
    let imageStyles = `url(${url})`;
    let objectStyles:any =  { 'background-image': imageStyles, 'background-position': `50% 50%` }
    return this.sanitizer.sanitize(SecurityContext.STYLE, this.sanitizer.bypassSecurityTrustStyle(objectStyles))
  }

  removeHtmlTags(str: string): string {
    return str.replace(/<[^>]*>/g, '');
  }

  limitCharacters(str: string): string {
    return str.slice(0, 180);
  }

  getBlogSummary(body) {
    const plainString = this.removeHtmlTags(body);
    let shortString = this.limitCharacters(plainString);
    shortString = shortString.concat('...');
    return shortString
  }

  openArticle(blog): void {
    this.router.navigate(['/articulo', blog.urlTitle]);
  }

  loadMore() {
    this.displayLimit = this.displayLimit + 3
    if(this.displayLimit > this.blogs.length) {
      this.displayLimit = this.blogs.length
    }
    this.getBlogsToShow()
  }

  getBlogsToShow() {
    return this.blogs.slice(0, this.displayLimit)
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.carouselConfig.showNavigationArrows = false
    this.carouselConfig.showNavigationIndicators = false

  }

}
