import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { NgxSpinnerService } from "ngx-spinner";
import swal from 'sweetalert2'

@Component({
  selector: 'app-send-price',
  templateUrl: './send-price.component.html',
  styleUrls: ['./send-price.component.scss']
})
export class SendPriceComponent implements OnInit {

  @Input() data
  @Input() type
  public mailData: any

  constructor(public activeModal: NgbActiveModal, private http: HttpClient, private spinner: NgxSpinnerService) { }

  sendMail() {
    this.spinner.show();
    this.mailData.type = 'price'
    this.mailData.priceType = this.type
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    this.http.post(' https://us-central1-ledu-web-page.cloudfunctions.net/sendNotificationMail', this.mailData, httpOptions)
      .subscribe((res: any) => {
        console.log('MAIL SENT')
        console.log(res)
        this.spinner.hide();
        this.mailData = {}
        swal.fire({
          icon: 'success',
          title: '¡Mensaje Enviado!',
          text: 'Revisaremos tu mensaje y te contactaremos lo antes posible.',
          showConfirmButton: false,
          timer: 2000
        })
        this.activeModal.dismiss('Cross click')
      },
      (err) => {
        console.log(err)
        this.mailData = {}
      })
  }

  ngOnInit() {
    this.mailData = {
      clientSubject: 'Solicitud de cotización de ' + this.data.name
    }
  }

}
