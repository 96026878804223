import { Component, OnInit, Input, Output, EventEmitter, SecurityContext } from '@angular/core';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'blog-admin-main',
  templateUrl: './blog-admin-main.component.html',
  styleUrls: ['./blog-admin-main.component.scss']
})
export class BlogAdminMainComponent implements OnInit {


  @Input() blogs
  @Output() getViewChange = new EventEmitter<any>();
  private _currentViewSub
  
  public get currentViewSub() : any {
    return this._currentViewSub
  }
  
  public set currentViewSub(v : any) {
    this._currentViewSub = v
    this.getViewChange.emit(v)
  }

  public filteredBlogs
  public filters: any = {
    stringToSearch: '',
    orderBy: {}
  }
  public options = {
    orderBy: [
      {key: 'title', name:'Titulo', icon: 'fas fa-box'},
      {key: 'created_at', name:'Fecha de Creación', icon: 'fas fa-calendar'}
    ]
  }

  constructor(private sanitizer: DomSanitizer) { }

  setSelected(selected, type) {
    this.filters[type] = selected;
    this.applyFilters()
  }

  newBlog() {
    this.getViewChange.emit({view: 'new', data: ''})
  }

  edithBlog(blog) {
    this.getViewChange.emit({view: 'edit', data: blog.$id})
  }

  applyFilters() {
    let tempArray = this.blogs

    //BY STRING
    if (this.filters.stringToSearch !== '') {
      tempArray = tempArray.filter(e => {
        let lowercase = this.filters.stringToSearch.toLowerCase();
        let elementData = e.title
        return elementData.toLowerCase().includes(lowercase);
      })
    }
    //ORDER BY
    if (tempArray.length) {
      tempArray = _.orderBy<any>(tempArray, this.filters.orderBy.key, 'asc')
    }

    this.filteredBlogs = tempArray;
  }

  getImageStyles(image) {
    let url = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(image))
    let imageStyles = `url(${url})`;
    let objectStyles:any =  { 'background-image': imageStyles, 'background-position': `50% 50%` }
    return this.sanitizer.sanitize(SecurityContext.STYLE, this.sanitizer.bypassSecurityTrustStyle(objectStyles))
  }

  removeHtmlTags(str: string): string {
    return str.replace(/<[^>]*>/g, '');
  }

  limitCharacters(str: string): string {
    return str.slice(0, 180);
  }

  getBlogSummary(body) {
    const plainString = this.removeHtmlTags(body);
    let  shortString = this.limitCharacters(plainString);
    shortString = shortString.concat('...');
    return shortString
  }

  ngOnInit() {
    this.filters.orderBy = this.options.orderBy[1]
    this.applyFilters()
  }

  ngOnChanges(changes:any) {
    this.applyFilters()
  }

}
