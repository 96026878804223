import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'solutions-sectors',
  templateUrl: './solutions-sectors.component.html',
  styleUrls: ['./solutions-sectors.component.scss']
})
export class SolutionsSectorsComponent implements OnInit {

  @Input() sector

  constructor() { }

  ngOnInit() {
  }

}
