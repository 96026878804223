import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'about-us-hwa',
  templateUrl: './about-us-hwa.component.html',
  styleUrls: ['./about-us-hwa.component.scss']
})
export class AboutUsHwaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
