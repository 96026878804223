import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'solutions-definitions',
  templateUrl: './solutions-definitions.component.html',
  styleUrls: ['./solutions-definitions.component.scss']
})
export class SolutionsDefinitionsComponent implements OnInit {

  public definitions = [
    {
      name:'Video vigilancia',
      image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fsolutions-services%2FVideovigilancia.png?alt=media&token=2cd5c15e-7c77-4adc-baa8-f460fb2dd8cc',
      text: 'Protege tu hogar o empresa, observa en tiempo real tu hogar o empresa desde tu Smartphone, además de mantener grabada evidencia.'
    },
    {
      name:'GPS',
      image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fsolutions-services%2Fgps.png?alt=media&token=4f9d166d-c345-44d0-b449-bfcdc20ca046',
      text: 'Localización vehicular las 24 horas, controla la ubicación de tus unidades de transporte, aumenta la seguridad y productividad de tu vehículo.'
    },
    {
      name:'Alarma contra incendio',
      image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fsolutions-services%2Fincendios.png?alt=media&token=e6551d80-2d0c-4333-adc8-3029e79a16a6',
      text: 'Un sistema de alarma contra incendio se encarga de alertar sobre la presencia de humo o calor por medio de sirenas, luces y emisión de sonido.'
    },
    {
      name:'Monitoreo',
      image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fsolutions-services%2Fmonitoreo.png?alt=media&token=ce894a81-5f2a-42fd-a825-774f66394e4f',
      text: 'Nuestra Central de Monitoreo LEDU es la más grande y avanzada del centro del país, en el que son atendidas las señales de emergencia de tu hogar y empresa 24/7, todo el año. Usted cuenta con el respaldo de un equipo de profesionales que te darán ayuda en caso de emergencia y enviarán a la ayuda necesaria.'
    },
    {
      name:'Control de acceso',
      image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fsolutions-services%2Fcontrol%20de%20acceso.png?alt=media&token=5ebcafa8-713a-4dab-9eaf-27bf6648d80e',
      text: 'Sistema que nos permite restringir el acceso a una o varias áreas de una empresa u hogar, si es electrónico nos permite además tener un registro de los accesos y nos da el beneficio de saber en dónde, cuándo y quien es la persona que está accediendo.'
    },
    {
      name:'Alarmas',
      image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fsolutions-services%2Falarmas.png?alt=media&token=70b7308b-5e22-4ad4-bc86-a8e68abae771',
      text: 'Nuestros sistemas de alarma cuentan con dispositivos de alta tecnología que protegen el interior y perímetro del inmueble, cuentan con sensores de movimiento, contactos magnéticos de puertas y ventanas, sirenas que alertan, además del teclado de alarma donde se puede controlar el sistema.'
    }
  ]

  constructor() { }

  ngOnInit() {
  }

}
