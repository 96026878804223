// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBatGOIXJRugqDzWtuneP5WY1Ljk4_ESpE",
    authDomain: "ledu-web-page.firebaseapp.com",
    projectId: "ledu-web-page",
    storageBucket: "ledu-web-page.appspot.com",
    messagingSenderId: "961261865177",
    appId: "1:961261865177:web:0173a96d67c681ed09d6c8",
  },
  clientId: 'AaF_jB0qh4-g0UXkTDkOrKxIr6GqRyG5WRd_YkcmjB-uAu5JxpW98PLM9AZe7K01HLkXfl4MvY0KDrxD',
  // clientId: 'AeRhlQ8Cn6gKf04H7rbnv8s81ASQwjoboJcTRcqa_oIRMN1oSZZ2IAdlpA6DGoi27_PsM2eQpE5_GEUA',
  saiECommerceURL: 'https://ledu.ddns.net/alphaerpweb/defaultE.aspx'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
