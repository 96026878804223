import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FirestoreService } from '../services/firestore/firestore.service'
import { FilesUploadService } from '../services/firestore/files-upload.service'
import { MainService } from '../services/core/main.service'
import swal from 'sweetalert2'
import * as _ from 'lodash'

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  @Input() user
  public editData
  public isHovering: boolean
  public image: any
  public previewURL
  public alreadyImage: boolean = false
  public selectedRole: any
  public rolesList

  constructor(
    public activeModal: NgbActiveModal,
    private firestoreService: FirestoreService,
    private filesUploadService: FilesUploadService,
    private mainService: MainService
  ) {
    this.rolesList = mainService.rolesList
  }

  setSelected(selected) {
    this.selectedRole = selected;
  }

  getValues(object) {
    return _.map(object, (value, key) => {
      value.key = key
      return value;
    })
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  removeImage() {
    this.image = null;
    this.previewURL = null;
  }

  setFile(event: FileList) {
    this.image = event;
    var reader = new FileReader();
    reader.readAsDataURL(event.item(0));
    reader.onload = (e) => {
      this.previewURL = reader.result;
    }
  }

  newPhoto() {
    this.alreadyImage = false
  }

  saveChanges() {
    let editDataTemp: any = {
      name: this.editData.name,
      lastName: this.editData.lastName,
      role: this.selectedRole.key,
    }
    if (!this.alreadyImage && this.image) {
      this.filesUploadService.uploadFile(this.image, `users/photos`, true).then(downloadURL => {
        editDataTemp.photo = downloadURL
        this.updateProcess(editDataTemp, this.user.$id)
      })
    } else {
      this.updateProcess(editDataTemp, this.user.$id)
    }
  }


  updateProcess(data, userId) {
    this.firestoreService.update(userId, data, 'users').then(_ => {
      swal.fire({
        icon: 'success',
        title: '¡Felicidades!',
        text: 'La información del usuario se actualizó con éxito',
        showConfirmButton: false,
        timer: 2000
      })
      this.activeModal.close('save')
    })
  }

  ngOnInit() {
    this.editData = {...this.user}
    if (this.user.photo) {
      this.alreadyImage = true
    }
    this.selectedRole = this.rolesList[this.user.role]
  }
}
