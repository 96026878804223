import { Component, OnInit, HostListener } from '@angular/core';
import { MainService } from '../services/core/main.service';
import { AuthService } from '../services/core/auth.service';
import { StorageService } from '../services/core/storage.service';

declare const window: any;
declare var gtag;

@Component({
  selector: 'topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  showScroll: boolean;
  showScrollHeight = 300;
  hideScrollHeight = 10;

  public menuOptions = [
    { key: 'inicio', value: 'Inicio', show: true },
    { key: 'nosotros', value: 'Nosotros', show: true },
    { key: 'soluciones-y-servicios', value: 'Soluciones y Servicios', show: true },
    { key: 'usuario-ledu', value: 'Usuarios', show: true },
    { key: 'contacto', value: 'Contacto', show: true },
    { key: 'blog', value: 'Blog', show: true },
    { key: 'promociones', value: 'Promociones', show: true },
    { key: 'panel-de-usuario', value: 'Panel de Usuario', show: false }
  ]
  whiteTopbar: boolean
  public checkIfUser
  private _cart

  public get cart(): any {
    return this.mainService.currentCart
  }


  constructor(
    private mainService: MainService,
    private authService: AuthService,
    private storageService: StorageService
  ) {
    this.whiteTopbar = mainService.solidTopbar
    this.checkIfUser = mainService.checkIfUser
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (!this.mainService.solidTopbar) {
      if (scrollOffset >= 120) {
        document.querySelectorAll('.toolbar').forEach((c) => {
          c.classList.add('toolbar-solid');
        });
        document.querySelectorAll('.sidemenu').forEach((c) => {
          c.classList.add('sidemenu-solid');
        });
      } else {
        document.querySelectorAll('.toolbar').forEach((c) => {
          c.classList.remove('toolbar-solid');
        });
        document.querySelectorAll('.sidemenu').forEach((c) => {
          c.classList.remove('sidemenu-solid');
        });
      }
    }
    if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight) {
      this.showScroll = true;
    }
    else if (this.showScroll && (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight) {
      this.showScroll = false;
    }
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 5));
      }
    })();
  }


  getOptionsToShow() {
    return this.menuOptions.filter(o => {
      return o.show
    })
  }

  openWhatsapp() {
    let link = 'https://api.whatsapp.com/send?phone=524491106030&text=Hola!%20Quiero%20contratar%20sus%20servicios!'
    window.open(link, '_blank').focus();
    // Adwords Conversion
    gtag('event', 'conversion', {
      'send_to': 'AW-635950918/dxKdCOS5xIAYEMaun68C'
    });
  }

  ngOnInit() {
    this.mainService.solidTopbarChange.subscribe(value => {
      if (value) {
        document.querySelectorAll('.toolbar').forEach((c) => {
          c.classList.add('toolbar-solid');
        });
        document.querySelectorAll('.sidemenu').forEach((c) => {
          c.classList.add('sidemenu-solid');
        });
      } else {
        document.querySelectorAll('.toolbar').forEach((c) => {
          c.classList.remove('toolbar-solid');
        });
        document.querySelectorAll('.sidemenu').forEach((c) => {
          c.classList.remove('sidemenu-solid');
        });
      }
    });
    this.authService.getCurrentUserData()
      .then(user => {
        if (user) {
          let option = this.menuOptions.find(o => {
            return o.key === 'panel-de-usuario'
          })
          let index = this.menuOptions.indexOf(option)
          this.menuOptions[index].show = true
        }
      })
    if (this.storageService.exist('cart')) {
      let cart = this.storageService.get('cart')
      this.mainService.setNewCart(cart)
    }
    if (this.storageService.exist('order')) {
      let cart = this.storageService.get('order')
      this.mainService.saveProductsToOrder(cart)
    }
  }

}
