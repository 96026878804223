import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MainService } from '../services/core/main.service';
import { FirestoreService } from '../services/firestore/firestore.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Lightbox } from 'ngx-lightbox';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  private routeSub: Subscription
  public product: any = {}
  public quantity: number = 1
  public selectedImage: any

  constructor(
    private route: ActivatedRoute,
    private firestoreService: FirestoreService,
    private spinner: NgxSpinnerService,
    private _lightbox: Lightbox,
    private mainService: MainService,
    private titleService: Title, 
    private metaService: Meta
    ) { }

  quantityMod(quantity) {
    this.quantity = this.quantity + quantity
    if(this.quantity < 1) {
      this.quantity = 1
    }
  }

  setSelectedImage(image) {
    this.selectedImage = image
  }

  addToCart() {
    this.mainService.addToCart({$id: this.product.$id, quantity: this.quantity})
  }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(true)
    window.scrollTo(0, 0);
    this.spinner.show()
    this.routeSub = this.route.params.subscribe(params => {
        this.firestoreService.get(params.id, 'products').subscribe(doc => {
          let product = doc.data()
          product.$id = doc.id
          this.product = product
          console.log(this.product)
          this.selectedImage = this.product.mainImage
          this.titleService.setTitle(`${this.product.name} - LEDU Vidas Tranquilas`);
          this.metaService.addTags([
            { name: 'description', content: this.product.name }
          ])
          this.spinner.hide()
        })
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

}
