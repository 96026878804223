import { Component, OnInit, Input, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

declare var gtag;

@Component({
  selector: 'promotions-packages',
  templateUrl: './promotions-packages.component.html',
  styleUrls: ['./promotions-packages.component.scss']
})
export class PromotionsPackagesComponent implements OnInit {

  public textureImages = [
    'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2FAlambrico.jpg?alt=media&token=72e72bd3-acf2-470d-b099-6723eb21e52a',
    'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2FINA%CC%81LAMBRIO%20ECO.jpg?alt=media&token=a2cf21c0-06ac-43e7-8344-cb0ae61588e7',
    'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2FPlus-m.jpg?alt=media&token=28a98cf2-e57b-461c-8dc3-b418cfda49a9',
    'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2Faxpro%20eco.jpg?alt=media&token=b4dd29a7-36a5-4a0c-883a-ff0a5ebede98',
    'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2Faxpro%20plus-m.jpg?alt=media&token=844c083b-871d-42d7-83b5-f91fb1f3049f',
    'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2FSistema%20videovigilancia.jpg?alt=media&token=c54d12ec-4e2e-4daf-91b9-3f0425227c9f',
    'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2FPromocio%CC%81n%20enero.jpg?alt=media&token=1135d8d8-b0ea-490f-981e-ad698b340695',
  ]

  public packages = [
    {
      id: 'inalambrico-eco',
      name1: 'PAQUETE',
      name2: 'INALÁMBRICO',
      subName: 'ECO',
      types: [
        {
          name: 'BÁSICO',
          color: '#76b930',
          image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2FInalambrico-basico.jpg?alt=media&token=11e2ac53-eb70-4f30-8d57-84cac842e5ee',
          features: [
            '1 Panel de 48 zonas',
            '1 Teclado',
            '1 Batería de gel recargable',
            '1 Sirena interior',
            '1 Sensor de movimiento',
            '2 Contactos magnéticos',
            '2 Placas disuasivas',
            '1 Comunicador GPRS',
          ],
          priceAdvance: '$4,500.',
          priceMonthlyA: '$570.',
          priceMonthlyB: "$600.",
          priceZoneA: '$13,600.',
          priceZoneB: '$14,500.'
        },
        {
          name: 'STANDARD',
          color: '#345ba6',
          image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2Falambrico-standard.jpg?alt=media&token=44292d6a-8631-4078-bd8b-f9520627a3d8',
          features: [
            '1 Plan Básico',
            '2 Cámaras de 2 mpx',
            '1 Grabadora turbo HD',
            '1 Memoria de un TB',
            '1 App Móvil',
            '30 mts de cable para c/u',
          ],
          priceAdvance: '$5,900.',
          priceMonthlyA: '$750.',
          priceMonthlyB: '$800.',
          priceZoneA: '$17,900.',
          priceZoneB: '$18,800.'
        } ,
        // {
        //   name: 'PREMIUM',
        //   color: '#00954e',
        //   image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2FInalambrico-premium.jpg?alt=media&token=bfa74674-3547-477a-ad85-e3ddb6c51d9b',
        //   features: [
        //     '1 Plan Básico',
        //     '1 Plan Standard',
        //     '1 Frente de Calle',
        //     '1 Pantalla de 7\"',
        //     '50 mts de cable',
        //     '*Instalación',
        //   ],
        //   priceAdvance: '$6,900.',
        //   priceMonthlyA: '$860',
        //   priceMonthlyB: '$920',
        //   priceZoneA: '$20,600',
        //   priceZoneB: '$21,500.'
        // }  
      ]
    },
    {
      id: 'inalambrico-plus',
      name1: 'PAQUETE',
      name2: 'INALÁMBRICO',
      subName: 'PLUS ',
      types: [
        {
          name: 'BÁSICO',
          color: '#76b930',
          image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2FInalambrico-basico.jpg?alt=media&token=11e2ac53-eb70-4f30-8d57-84cac842e5ee',
          features: [
            '1 Panel de 48 zonas',
            '1 Teclado',
            '1 Batería de gel recargable',
            '1 Sirena interior',
            '2 Sensores de movimiento',
            '3 Contactos magnéticos',
            '2 Placas disuasivas',
            '1 Llavero para activar y desactivar',
            '1 Comunicador GPRS',
          ],
          priceAdvance: '$5,250.',
          priceMonthlyA: '$660.',
          priceMonthlyB: "$700.",
          priceZoneA: '$15,800.',
          priceZoneB: '$16,700.',
          noMmto: true
        },
        {
          name: 'STANDARD',
          color: '#345ba6',
          image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2FInalambrico-standard.jpg?alt=media&token=3281a9ea-eb71-455b-bf37-190b3f7c7bbb',
          features: [
            '1 Kit alarmas PLUS',
            '2 Cámaras de 2 mpx',
            '1 Grabadora turbo HD',
            '1 Memoria de un TB',
            '1 App Móvil',
            '30 mts de cable para c/u',
          ],
          priceAdvance: '$6,700.',
          priceMonthlyA: '$850.',
          priceMonthlyB: '$900.',
          priceZoneA: '$20,300.',
          priceZoneB: '$21,200.'
        } ,
        // {
        //   name: 'PREMIUM',
        //   color: '#00954e',
        //   image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2Faxpro%20plus-premium.jpg?alt=media&token=2692987b-3099-4c33-bc84-31b44430bb19',
        //   features: [
        //     '1 Plan Básico',
        //     '1 Plan Standard',
        //     '1 Frente de Calle',
        //     '1 Pantalla de 7\"',
        //     '50 mts de cable',
        //     '*Instalación',
        //   ],
        //   priceAdvance: '$7,600.',
        //   priceMonthlyA: '$950.',
        //   priceMonthlyB: '$1,000.',
        //   priceZoneA: '$22,800.',
        //   priceZoneB: '$23,700.'
        // }  
      ]
    },
    {
      id: 'alambrico',
      name1: 'PAQUETE',
      name2: 'ALÁMBRICO',
      types: [
        {
          name: 'BÁSICO',
          color: '#76b930',
          image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2FInalambrico-basico.jpg?alt=media&token=11e2ac53-eb70-4f30-8d57-84cac842e5ee',
          features: [
            '1 Panel de 48 zonas',
            '1 Teclado',
            '1 Batería de gel recargable',
            '1 Sirena interior',
            '1 Sensores de movimiento',
            '2 Contactos magnéticos',
            '2 Placas disuasivas',
            '1 Comunicador GPRS',
          ],
          priceAdvance: '$3,960.',
          priceMonthlyA: '$490.',
          priceMonthlyB: "$530.",
          priceZoneA: '$11,800.',
          priceZoneB: '$12,700.'
        },
        {
          name: 'STANDARD',
          color: '#345ba6',
          image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2Falambrico-standard.jpg?alt=media&token=44292d6a-8631-4078-bd8b-f9520627a3d8',
          features: [
            '1 Plan básico',
            '2 Cámaras de 2 mpx',
            '1 Grabadora turbo HD',
            '1 Memoria de un TB',
            '1 App Móvil',
            '30 mts de cable para c/u',
          ],
          priceAdvance: '$5,400.',
          priceMonthlyA: '$670.',
          priceMonthlyB: '$710.',
          priceZoneA: '$16,100.',
          priceZoneB: '$17,000.'
        } ,
        // {
        //   name: 'PREMIUM',
        //   color: '#00954e',
        //   image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2Falambrico-premium.jpg?alt=media&token=32940f0e-5f4e-4db7-a4d1-6b6c3cc6b320',
        //   features: [
        //     '1 Plan Básico',
        //     '1 Plan Standard',
        //     '1 Frente de Calle',
        //     '1 Pantalla de 7\"',
        //     '50 mts de cable',
        //     '*Instalación',
        //   ],
        //   priceAdvance: '$6,350.',
        //   priceMonthlyA: '$790',
        //   priceMonthlyB: '$840',
        //   priceZoneA: '$19,000',
        //   priceZoneB: '$19,900.'
        // }  
      ]
    },
    {

      id: 'axpro-eco',
      name1: 'PAQUETE INALÁMBRICO',
      name2: 'AXPRO ECO',
      types: [
        {
          name: 'BÁSICO',
          color: '#76b930',
          image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2Faxpro%20plus-basico.jpg?alt=media&token=1cf56b3d-d254-4433-a314-26942fddf673',
          features: [
            '1 Panel de 48 zonas con comunicador GPRS',
            '1 Sensores de movimiento',
            '2 Contactos magnéticos',
            '2 Placas disuasivas',
            '1 Llavero',
          ],
          priceAdvance: '$4,000.',
          priceMonthlyA: '$500.',
          priceMonthlyB: "$550.",
          priceZoneA: '$12,000.',
          priceZoneB: '$12,900.'
        },
        {
          name: 'STANDARD',
          color: '#345ba6',
          image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2Faxpro%20plus-standard.jpg?alt=media&token=eccf1388-4fc6-4022-81bd-0af48da92c50',
          features: [
            '1 Plan básico ECO',
            '2 Cámaras de 2 mpx',
            '1 Grabadora turbo HD',
            '1 Memoria de un TB',
            '1 App Móvil',
            '30 mts de cable para c/u',
          ],
          priceAdvance: '$5,400.',
          priceMonthlyA: '$680.',
          priceMonthlyB: '$720.',
          priceZoneA: '$16,300.',
          priceZoneB: '$17,200.'
        } ,
        // {
        //   name: 'PREMIUM',
        //   color: '#00954e',
        //   image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2FInalambrico-premium.jpg?alt=media&token=bfa74674-3547-477a-ad85-e3ddb6c51d9b',
        //   features: [
        //     '1 Plan Básico ECO',
        //     '1 Plan Standard',
        //     '1 Videoportero',
        //     '1 Frente de Calle',
        //     '1 Pantalla de 7\"',
        //     '50 mts de cable',
        //     '*Instalación',
        //   ],
        //   priceAdvance: '$6,400.',
        //   priceMonthlyA: '$800.',
        //   priceMonthlyB: '$850.',
        //   priceZoneA: '$19,200.',
        //   priceZoneB: '$20,100.'
        // }  
      ]
    },
    {
      id: 'axpro-plus',
      name1: 'PAQUETE INALÁMBRICO',
      name2: 'AXPRO PLUS',
      types: [
        {
          name: 'BÁSICO',
          color: '#76b930',
          image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2Faxpro%20plus-basico.jpg?alt=media&token=1cf56b3d-d254-4433-a314-26942fddf673',
          features: [
            '1 Panel de 48 zonas con comunicador GPRS',
            '2 Sensores de movimiento',
            '2 Contactos magnéticos',
            '2 Placas disuasivas',
            '1 Llavero',
          ],
          priceAdvance: '$4,600.',
          priceMonthlyA: '$580.',
          priceMonthlyB: "$610.",
          priceZoneA: '$13,900.',
          priceZoneB: '$14,800.',
          noMmto: true
        },
        {
          name: 'STANDARD',
          color: '#345ba6',
          image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2Faxpro%20plus-standard.jpg?alt=media&token=eccf1388-4fc6-4022-81bd-0af48da92c50',
          features: [
            '1 Plan básico PLUS',
            '2 Cámaras de 2 mpx',
            '1 Grabadora turbo HD',
            '1 Memoria de un TB',
            '1 App Móvil',
            '30 mts de cable para c/u',
          ],
          priceAdvance: '$6,000.',
          priceMonthlyA: '$750.',
          priceMonthlyB: '$800.',
          priceZoneA: '$18,000.',
          priceZoneB: '$18,900.'
        } ,
        // {
        //   name: 'PREMIUM',
        //   color: '#00954e',
        //   image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2Faxpro%20plus-premium.jpg?alt=media&token=2692987b-3099-4c33-bc84-31b44430bb19',
        //   features: [
        //     '1 Plan Básico PLUS',
        //     '1 Plan Standard',
        //     '1 Videoportero',
        //     '1 Frente de Calle',
        //     '1 Pantalla de 7\"',
        //     '50 mts de cable',
        //     '*Instalación',
        //   ],
        //   priceAdvance: '$7,000.',
        //   priceMonthlyA: '$870.',
        //   priceMonthlyB: '$930.',
        //   priceZoneA: '$20,900.',
        //   priceZoneB: '$21,800.'
        // }  
      ]
    }
  ]

  public videoPackage = [
    {
      name: 'PAQUETE 1',
      content: '1 Cámara y su videograbador',
      price: '$5,090',
      color: '#8CD240'
    },
    {
      name: 'PAQUETE 2',
      content: '2 Cámaras y su videograbador',
      price: '$6,390',
      color: '#78BA31'
    },
    {
      name: 'PAQUETE 3',
      content: '3 Cámara y su videograbador',
      price: '$7,690',
      color: '#5F9920'
    },
    {
      name: 'PAQUETE 4',
      content: '4 Cámara y su videograbador',
      price: '$8,950',
      color: '#437112'
    }
  ]

  public videopackageFeatures = [
    '# cámaras bala de policarbonato',
    '1 grabador 4 CH',
    'Hasta 30 mts de cable para c/u',
    '1 par de transeptores para c/u',
    '1 disco duro de un TB',
    '1 app móvil',
    'Instalación'
  ]

  public monthlyPromotion = 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2FPromocio%CC%81n%20enero.png?alt=media&token=d1ecef3f-7679-4a0b-8e86-04ca3b3d205b'

  constructor(private sanitizer: DomSanitizer) { }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 5));
      }
    })();
  }

  goToPackage(id) {
    let element = document.getElementById(id);
    if (!element) return
    if (element.getBoundingClientRect() !== null) {

      let offset = 80;

      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      })

    }
  }

  openWhatsapp() {
    let link = 'https://api.whatsapp.com/send?phone=524491106030&text=Hola!%20Quiero%20contratar%20sus%20servicios!'
    window.open(link, '_blank').focus();
    // Adwords Conversion
    gtag('event', 'conversion', {
      'send_to': 'AW-635950918/dxKdCOS5xIAYEMaun68C'
    });
  }

  getImageStyles(image) {
    let url = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(image))
    let imageStyles = `url(${url})`;
    let objectStyles:any =  { 'background-image': imageStyles, 'background-position': `50% 50%` }
    return this.sanitizer.sanitize(SecurityContext.STYLE, this.sanitizer.bypassSecurityTrustStyle(objectStyles))
  }

  ngOnInit() {
  }

}
