import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServicesDetailsComponent } from '../services-details/services-details.component';

@Component({
  selector: 'services-cards',
  templateUrl: './services-cards.component.html',
  styleUrls: ['./services-cards.component.scss']
})
export class ServicesCardsComponent implements OnInit {

  @Input() data
  @Input() title
  @Input() subtitle
  public selectedCard = {}

  constructor(private modalService: NgbModal) { }

  openDetails(card) {
    const modalRef = this.modalService.open(ServicesDetailsComponent, { size: 'lg', centered: true, keyboard: true, windowClass : "xl-modal"});
    modalRef.componentInstance.data = card;
    let isService = this.subtitle === 'SERVICIOS' ? true : false
    modalRef.componentInstance.isService = isService;
  }

  ngOnInit() {
  }

}
