import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/core/main.service';

@Component({
  selector: 'app-placed-order',
  templateUrl: './placed-order.component.html',
  styleUrls: ['./placed-order.component.scss']
})
export class PlacedOrderComponent implements OnInit {

  constructor(private mainService: MainService) { }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(true)
    this.mainService.clearCart()
    window.scrollTo(0, 0);
  }

}
