import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../services/firestore/firestore.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as _ from 'lodash';

@Component({
  selector: 'app-orders-manager',
  templateUrl: './orders-manager.component.html',
  styleUrls: ['./orders-manager.component.scss']
})
export class OrdersManagerComponent implements OnInit {

  public orders: any = []
  public products: any = []

  constructor(
    private firestoreService: FirestoreService,
    private spinner: NgxSpinnerService,
  ) { }

  getOrders() {
    return this.orders = _.orderBy<any>(this.orders, 'created_at', 'desc')
  }

  ngOnInit() {
    this.spinner.show();
    this.firestoreService.getCollection('orders').subscribe(snapshot => {
      this.orders = []
      snapshot.forEach((data: any) => {
        let order = data.payload.doc.data()
        order.$id = data.payload.doc.id
        this.orders.push(order)
      })
      this.firestoreService.getCollection('products').subscribe(snapshot => {
        this.products = []
        snapshot.forEach((data: any) => {
          let product = data.payload.doc.data()
          product.$id = data.payload.doc.id
          this.products.push(product)
        })
        this.spinner.hide();
      })
    })
  }

}
