import { Component, OnInit, Input } from '@angular/core';
import { PaymentsService } from '../services/core/payments.service';
import { get } from 'scriptjs';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'mercadopago-button',
  templateUrl: './mercadopago-button.component.html',
  styleUrls: ['./mercadopago-button.component.scss']
})
export class MercadopagoButtonComponent implements OnInit {

  @Input() products

  constructor(private paymentsService: PaymentsService, private spinner: NgxSpinnerService) { }

  public preference: any = {
    back_urls: {
      success: 'https://ledu.mx/finalizar-pedido',
      failure: 'https://ledu.mx/finalizar-pedido',
      pending: 'https://ledu.mx/finalizar-pedido'
    },
    auto_return: 'approved',
    payment_methods: {
      excluded_payment_types: [
        {
          id: "ticket",
        },
        {
          id: "atm",
        },
        {
          id: "bank_transfer",
        },
        {
          id: "digital_currency",
        },
        {
          id: "digital_wallet",
        }
      ]
    }
  }
  public init_point

  onBuy() {
    this.spinner.show()
    this.preference.items = this.products.map(p => {
      let unit_price = p.sale ? p.discount : p.price
      let product = {
        title: p.name,
        unit_price: Number(unit_price),
        quantity: p.quantity
      }
      return product
    })
    console.log(this.preference)
    this.paymentsService.goCheckOut(this.preference).then(result => {
      this.init_point = result.data.result
      window.location.href = this.init_point
    }).catch(error => {
      console.log(error)
      return error
    });
  }

  ngOnInit() {
    get("https://www.mercadopago.com.mx/integrations/v1/web-payment-checkout.js", () => {
    })
  }

}
