import { Injectable } from '@angular/core';
import { auth } from 'firebase';
import { Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import * as firebase from 'firebase/app';
import 'firebase/analytics';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  solidTopbar: boolean
  solidTopbarChange: Subject<boolean> = new Subject<boolean>()

  public currentCart: any = []
  public currentCartChange: Subject<any> = new Subject<any>()
  public dataToOrder: any
  public dataToOrderChange: Subject<any> = new Subject<any>()

  public roles: any = {
    'admin': ['admin', 'modify-orders'],
    'user': ['usuario']
  }
  public rolesList: any = {
    'admin': { name: 'Administrador', color: '#2D3047', icon: 'fas fa-users-cog' },
    'user': { name: 'Usuario del Sitio', color: '#45516b', icon: 'fas fa-user-tag' },
  }

  public ordersStatus = [
    { key: 'pending', value: 'Recibido', color: '#a8a8a8'}, 
    { key: 'active', value: 'Procesando', color: '#347fc4' }, 
    { key: 'closed', value: 'Entregado', color: '#75bc1e' }, 
    { key: 'cancelled', value: 'Cancelada', color: '#cc4148' }
  ]

  public paymentStatus = [
    { key: 'pending', value: 'Pendiente', color: '#a8a8a8'}, 
    { key: 'paid', value: 'Pagado', color: '#75bc1e' }, 
  ]
  private analytics: any;

  constructor(private authService: AuthService, private storageService: StorageService) {
    this.analytics = firebase.analytics();
    this.solidTopbarChange.subscribe((value) => {
      this.solidTopbar = value
    });
    this.currentCartChange.subscribe((value) => {
      this.currentCart = value
    });
    this.dataToOrderChange.subscribe((value) => {
      this.dataToOrder = value
    });
  }

  addToCart(product) {
    let currentCart = this.currentCart
    if (!currentCart) {
      currentCart = []
    }
    let alreadyInCart = this.currentCart.find(p => {
      return p.$id === product.$id
    })
    if (!alreadyInCart) {
      currentCart.push(product)
    } else {
      let index = currentCart.indexOf(alreadyInCart)
      currentCart[index].quantity = currentCart[index].quantity + product.quantity
      this.currentCartChange.next(currentCart);
    }
      this.storageService.set('cart', currentCart)
  }

  setNewCart(cart) {
    this.currentCartChange.next(cart);
    this.storageService.set('cart', cart)
  }

  clearCart() {
    this.currentCartChange.next([]);
    this.dataToOrderChange.next([]);
    this.storageService.clear('cart')
    this.storageService.clear('order')
    this.storageService.clear('userInfo')
  }

  saveProductsToOrder(data) {
    this.dataToOrderChange.next(data);
    this.storageService.set('order', data)
  }

  checkIfUser() {
    const userData = this.authService.currentUser
    if (userData) {
      return true;
    } else {
      return false;
    }
  }

  checkRoleForRoutes(route, user) {
    const role = user.role
    if (this.roles[role].includes(route)) {
      return true;
    } else {
      return false;
    }
  }

  checkfeature(feature) {
    const userData = this.authService.currentUser
    if(userData) {
      const role = userData.role
      if (this.roles[role].includes(feature)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false
    }
  }

  toggleSolidTopbar(value) {
    this.solidTopbarChange.next(value);
  }

}
