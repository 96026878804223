import { Component, OnInit, Input, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'blog-article-recommended',
  templateUrl: './blog-article-recommended.component.html',
  styleUrls: ['./blog-article-recommended.component.scss']
})
export class BlogArticleRecommendedComponent implements OnInit {

  @Input() blogs

  constructor(private sanitizer: DomSanitizer, private router: Router) { }

  getImageStyles(image) {
    let url = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(image))
    let imageStyles = `url(${url})`;
    let objectStyles:any =  { 'background-image': imageStyles, 'background-position': `50% 50%` }
    return this.sanitizer.sanitize(SecurityContext.STYLE, this.sanitizer.bypassSecurityTrustStyle(objectStyles))
  }

  openBlog(blog) {
    this.router.navigate(['/articulo', blog.urlTitle]);
  }

  ngOnInit() {
    console.log(this.blogs)
  }

}
