import { Component, OnInit, Input, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FirestoreService } from '../services/firestore/firestore.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UserDetailsComponent } from '../user-details/user-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-users-manager',
  templateUrl: './users-manager.component.html',
  styleUrls: ['./users-manager.component.scss']
})
export class UsersManagerComponent implements OnInit {

  public users: any

  constructor(
    private firestoreService: FirestoreService,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal
  ) { }

  getImageStyles(image) {
    let url = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(image))
    let style = `url(${url})`
    let objectStyles: any = { 'background-image': style, 'background-position': `50% 50%` }
    return this.sanitizer.sanitize(SecurityContext.STYLE, this.sanitizer.bypassSecurityTrustStyle(objectStyles))
  }

  getUsersForRole(role) {
    return this.users.filter(u => {
      return u.role === role
    })
  }

  openUsersDetailModal(user) {
    const modalRef = this.modalService.open(UserDetailsComponent, { size: 'sm', centered: true, keyboard: false, backdrop: 'static', windowClass : "aside-modal" });
    modalRef.componentInstance.user = user;
  }

  ngOnInit() {
    this.spinner.show();
    this.firestoreService.getCollection('users').subscribe(snapshot => {
      this.users = []
      snapshot.forEach((data: any) => {
        let user = data.payload.doc.data()
        user.$id = data.payload.doc.id
        this.users.push(user)
      })
      this.spinner.hide();
    })
  }

}
