import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'contact-us-table',
  templateUrl: './contact-us-table.component.html',
  styleUrls: ['./contact-us-table.component.scss']
})
export class ContactUsTableComponent implements OnInit {

  constructor() { }

  public info:any = [
    { department: 'Ventas', ext: 'Ext. 604', whatsapp: ['449 110 6030'], mail: 'ventas@depsaledu.mx' },
    { department: 'Crédito y cobranza', ext: 'Ext. 601', whatsapp: ['449 215 4631'], mail: 'ingresos@depsaledu.mx' },
    { department: 'Instalaciones', ext: 'Ext. 600', whatsapp: ['449 234 9982'], mail: 'instalaciones@depsaledu.mx' },
    { department: 'Servicios', ext: 'Ext. 603', whatsapp: ['449 804 0045'], mail: 'aux_servicios@depsaledu.mx' },
    { department: 'Monitoreo', ext: 'Ext. 602', whatsapp: ['449 170 4869', '449 301 7967'], mail: 'central@depsaledu.mx' },
  ]

  ngOnInit() {
  }

}
