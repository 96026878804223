import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/core/main.service';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {

  constructor(private mainService: MainService) { }

  get solidTopbar(): boolean {
    return this.mainService.solidTopbar;
  }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(true)
  }

}
