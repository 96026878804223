import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  exist(type) {
    return localStorage.getItem(type) != null
  }

  set(type, data) {
    localStorage.setItem(type, JSON.stringify(data))
  }

  get(type) {
    return JSON.parse(localStorage.getItem(type))
  }

  clear(type) {
    localStorage.removeItem(type)
  }
  
}
