import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home-counters',
  templateUrl: './home-counters.component.html',
  styleUrls: ['./home-counters.component.scss']
})
export class HomeCountersComponent implements OnInit {

  public counters = [
    {name: 'Usuarios', before: '+', number: 6000 },
    {name: 'Equipos Instalados', before: '+',number: 15000 },
    {name: 'de Experiencia', before: '', number: 38, after:' AÑOS' },
    {name: 'Premios Recibidos', before: '+', number: 50 },
  ]

  constructor() { }

  ngOnInit() {
  }

}
