import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'about-us-clients-comments',
  templateUrl: './about-us-clients-comments.component.html',
  styleUrls: ['./about-us-clients-comments.component.scss'],
  providers: [NgbCarouselConfig]
})
export class AboutUsClientsCommentsComponent implements OnInit {

  public quotesIcon ='https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fcomillas%20icon.png?alt=media&token=6a2bfac5-2244-4bfc-aace-1825f8308c93'

  public comments = [
    { name: 'CARPLUS AUTOESTÉTICA', comment: 'Seguridad y tranquilidad para mi negocio gracias a LEDU', photo:'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fcomment%201.png?alt=media&token=1ee80c4f-f06f-402b-8121-e556432e7ab6'},
    { name: 'HECTOR ALEJANDRO RIOJA GARRIDO', comment: 'Simplemente lo mejor', photo:'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fcomment%202.png?alt=media&token=7742c865-56d7-4173-802e-000e899e4cf4'},
    { name: 'GUILLERMO GUTIERREZ', comment: 'Excelente servicio de alarmas', photo:'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fcomment%203.png?alt=media&token=2c569efe-d70a-4841-868a-e22f4710bca9'},
    { name: 'SAMANTHA GUADALUPE VARGAS GALVAN', comment: 'Siempre a la vanguardia, buena atención al cliente, puntualidad y compromiso en sus servicios; estoy realmente satisfecha con empresa LEDU, gracias por hacer la vida en el hogar un ambiente con tranquilidad y seguridad.', photo:'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fcomment%204.png?alt=media&token=20bd1b4a-ab3a-401b-9b5b-366e0b7a0727'},
    { name: 'AMBIENTALIA MX', comment: '¡Es verdad, vidas tranquilas! Desde que cuento con el servicio, nos sentimos tranquilos, y aunque hemos tenido falsas alarmas, siempre están al pendiente de las situaciones mínimas y mejorando su servicio en todos los sentidos.', photo:'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fcomment%205.png?alt=media&token=3e235809-ea4e-487c-9dd0-50d6b4ece7c0'},
    { name: 'ALE DURÁN', comment: 'Excelente servicio y una súper atención', photo:'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fcomment%206.png?alt=media&token=6a975f4e-fc7f-43a4-a46c-a3191592d70e'},
    { name: 'LUIS ARMANDO TORRES', comment: 'En el desarrollo del talento humano para entregar un excelente servicio de calidad.', photo:'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fcomment%207.png?alt=media&token=90731486-223b-4451-ac5a-b02d3aefcd43'},
    { name: 'JULIO POZAS', comment: 'La mejor compañía de protección electrónica en Aguascalientes.', photo:'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fcomment%208.png?alt=media&token=acb632ec-5e61-4089-8e1d-112afd8b0a28'},
    { name: 'JOSÉ GONZÁLEZ', comment: 'Desde hace más de 15 años he tenido la fortuna de contar con la protección de mi hogar y mi familia a través de esta increíble empresa LEDU que al día de hoy no me ha fallado... ¡Gracias por proteger lo que más amo!', photo:'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fcomment%209.png?alt=media&token=b7f32cd3-929f-40b8-bf52-6b175b1efc4e'},
    { name: 'JAIME JACOB AIZPURU GONZALEZ', comment: 'El mejor en sistema de seguridad.', photo:'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fcomment%2010.png?alt=media&token=6a2bcef3-83df-4419-8772-1add1e7694a5'},
    { name: 'OSCAR ENRIQUE GONZÁLEZ MARTÍNEZ', comment: 'Atención y trato profesional.', photo:'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fcomment%2011.png?alt=media&token=c33ac315-96a8-4ab0-af44-e42422024d65'},
    { name: 'JUAN ANTONIO HA', comment: 'Excelente atención y calidad de servicio.', photo:'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fcomment%2012.png?alt=media&token=b69fbadb-1c20-42e6-94c4-83258d29b370'}
  ]

  public splideOptions = { 
    type: 'loop', 
    perPage: 3, 
    keyboard: false,
    autoplay: true,
    speed: 2000,
    interval: 4000,
    perMove: 1,
    arrows: false,
    pagination: false,
    breakpoints: {
      575.98: {
        perPage: 1,
      },
      767.98: {
        perPage: 1,
      },
      991: {
        perPage: 2,
      },
    }
  }


  constructor(private carouselConfig: NgbCarouselConfig) {
    carouselConfig.interval = 4000;
    carouselConfig.wrap = true;
    carouselConfig.keyboard = true;
    carouselConfig.showNavigationArrows = false
    carouselConfig.showNavigationIndicators = false
  }

  ngOnInit() {
  }

}
