import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../services/firestore/firestore.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-sm-products',
  templateUrl: './sm-products.component.html',
  styleUrls: ['./sm-products.component.scss']
})
export class SmProductsComponent implements OnInit {

  public currentView:any = {view: 'main', data: ''}
  public products:any

  constructor(
    private firestoreService: FirestoreService,
    private spinner: NgxSpinnerService,
  ) { }

  getProductToEdit(id) {
    return this.products.find(p => {
      return p.$id === id
    })
  }

  getActiveProducts() {
    return this.products.filter(p => {
      return !p.disabled
    })
  }

  ngOnInit() {
    this.spinner.show();
    this.firestoreService.getCollection('products').subscribe(snapshot => {
      this.products = []
      snapshot.forEach((data: any) => {
        let user = data.payload.doc.data()
        user.$id = data.payload.doc.id
        this.products.push(user)
      })
      this.spinner.hide();
    })
  }

}
