import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/core/main.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {

  public images = [
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fpromotions%2Fheader%20promociones.jpg?alt=media&token=2c4aa7c1-cb5b-40b2-b946-9f1247cbd6c0',
      title: 'PAQUETES Y PROMOCIONES',
    }]


  constructor(private mainService: MainService, private titleService: Title, private metaService: Meta) { }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(false)
    this.titleService.setTitle('PAQUETES Y PROMOCIONES - LEDU Vidas Tranquilas');
    this.metaService.addTags([
      { name: 'description', content: 'Descubre nuestra gama de paquetes de seguridad. Diseñados para adaptarse a tus necesidades, brindando protección completa. ¡Asegura tu espacio con nosotros!' }, 
    ])
  }
}
