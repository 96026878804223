import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ledu-user-blem',
  templateUrl: './ledu-user-blem.component.html',
  styleUrls: ['./ledu-user-blem.component.scss']
})
export class LeduUserBlemComponent implements OnInit {

  public blemButton = 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fusers%2Fboton_blem.png?alt=media&token=ecb52951-6263-4dec-ad56-39b9cdbd3ad7'
  public features = [
    'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fusers%2Fbluetooth.png?alt=media&token=178716a0-065a-42bb-b3e9-786b2093d9fd',
    'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fusers%2Fwifi.png?alt=media&token=4e3c6b59-2ef4-4abf-9ade-1e715635f555',
    'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fusers%2Fapp.png?alt=media&token=39341746-cab7-43ea-b871-a5cd7a7ff0fc'
  ]
  public blem3rd = 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fusers%2F3ra%20edad.png?alt=media&token=9065f237-0538-4e4e-bf3b-ca99ade3b486'
  public protocolImg = 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fusers%2Fprotocolo%20blem.png?alt=media&token=c5a4ee48-3a89-4993-8fb1-07795a3fadb1'

  public requirements = [
    'Contar con un teléfono celular con datos que se encuentre encendido y cargado TODO el tiempo.',
    'Mantener el teléfono a una distancia menor a los 10 metros de donde se encuentre la persona con el botón BLEM.',
    'El botón se debe mantener oprimido por 3 segundos para que envíe su señal.',
    'Hacer una prueba por lo menos cada 15 días.',
    'El contacto a localizar debe proporcionar por lo menos 3 números telefónico distintos a los que se le podrá llamar en caso de que se reciba señal.',
    'El pago del servicio es por semestre anticipado.',
    'La batería se deberá cambiar por lo menos cada 2 años.'
  ]

  public scope = [
    'LEDU llamará en primer al celular al que se encuentra enlazado el BLEM de haber respuesta procederemos de acuerdo a los protocolos establecidos.',
    'Si la persona no responde y en el lugar hay número fijo llamaremos ahí.',
    'Si no ha comunicación procederemos de acuerdo con la lista de 3 números que el usuario nos indicó y actuaremos de acuerdo a los protocolos establecidos.'
  ]

  public protocol = [
    'Escuchar al usuario y tranquilizarlo hasta que reciba la ayuda solicitada.',
    'Reportar al familiar más cercano y/o un vecino de confianza que pueda asistirlo.',
    'Establecer comunicación y solicitar ayuda con el médico del usuario.',
    'Pedir el envío de una ambulancia en caso de requerirse.',
    'Informar a los contactos vía mensaje de WhatsApp del cierre del apoyo.'
  ]

  public exclusions = [
    'No asumimos la apertura del inmueble por ninguna causa.',
    'No prestar ayuda física ni suministrar medicamentos en ninguna circunstancia.',
    'No seremos responsable del tiempo que tarde en llegar la asistencia enviada.',
    'No podremos apoyar si el celular se encuentra descargado o no cuenta con datos.'
  ]

  constructor() { }

  ngOnInit() {
  }

}
