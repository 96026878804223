import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/core/main.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public images = [
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fslider%2Fslider-01.jpg?alt=media&token=3518d076-9c44-4c71-895a-ef9eb60fc815',
      title: '¿TU SEGURIDAD SE ADAPTA A LA ESTETICA DE TU NEGOCIO?',
      text: 'Vanguardia en tecnología y videovigilancia',
      icon: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Ficons%2Fslider_01_eye%20xl.png?alt=media&token=9f244aa0-74d2-4cce-969e-55aa844b4eaa'
    },
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fslider%2Fslider-02.jpg?alt=media&token=96eee7b0-5156-45b9-91d9-cf2b8b621c49',
      title: 'PROTEGE TU HOGAR',
      text: 'Sistemas de alarma que se adaptan a tus necesidades',
      icon: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Ficons%2Fslider_02_hand%20xl.png?alt=media&token=3dfb8311-14ba-47fd-9228-fbb420ef149e'
    },
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fslider%2Fslider-03.jpg?alt=media&token=5ac457f0-46d0-418f-b18d-2e5696cfea24',
      title: 'CONTROLA EL ACCESO DE TU PERSONAL A CARGO',
      text: 'La nueva generación de control de acceso la encuentras en LEDU',
      icon: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Ficons%2Fslider_03_house%20xl.png?alt=media&token=88859d32-b71e-41e7-96d8-af1eb0a92f74'
    },
  ]


  constructor(private mainService: MainService, private titleService: Title, private metaService: Meta) { }

  get solidTopbar(): boolean {
    return this.mainService.solidTopbar;
  }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(false)
    this.titleService.setTitle('LEDU Vidas Tranquilas');
    this.metaService.addTags([
      { name: 'description', content: 'LEDU es la empresa líder en Aguascalientes en soluciones de seguridad electrónica y monitoreo. Nuestro principal objetivo es proteger lo más importante para usted, su hogar, empresa, familia o bienes' },
    ])
  }

}
