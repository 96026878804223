import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'blog-article-body',
  templateUrl: './blog-article-body.component.html',
  styleUrls: ['./blog-article-body.component.scss']
})
export class BlogArticleBodyComponent implements OnInit {

  @Input() blog
  @Input() previousBlog
  @Input() nextBlog

  constructor(private router: Router) { }

  toPreviousBlog() {
    this.router.navigate(['/articulo', this.previousBlog]);
  }

  toNextBlog() {
    this.router.navigate(['/articulo', this.nextBlog]);
    
  }

  ngOnInit() { 

  }

}
