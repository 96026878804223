import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userData: Observable<any>;
  public currentUser: any;

  constructor(
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
  ) { }

  getCurrentUser(){
    return new Promise<any>((resolve, reject) => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
        }
      })
    })
  }

  getCurrentUserData(){
    let firestore = this.firestore
    return new Promise<any>((resolve, reject) => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          let observable = firestore.collection('users').doc(user.uid).get()
          observable.subscribe(doc => {
            let user = doc.data()
            user.$id = doc.id
            this.currentUser = user
            resolve(user)
          })
        } else {
          reject('No user logged in');
        }
      })
    })
  }

  SignIn(email, password) {
    return new Promise<any>((resolve, reject) => {
      this.angularFireAuth.auth.signInWithEmailAndPassword(email, password)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      });
    })
  }

  SignOut() {
    this.angularFireAuth.auth.signOut();
  }
}
