import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/core/main.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public images = [
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fcontact%2Fheader%20contact.jpg?alt=media&token=c4d02a1c-2560-4f71-a7a0-83f1854d6d8c',
      title: 'CONTACTO',
    }]

  public selectedLocation

  constructor(private mainService: MainService, private titleService: Title, private metaService: Meta) { }


  get solidTopbar(): boolean {
    return this.mainService.solidTopbar;
  }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(false)
    this.titleService.setTitle('CONTACTO - LEDU Vidas Tranquilas');
    this.metaService.addTags([
      { name: 'description', content: 'Comunicate con nosotros: 449 910 9930. Nuestra empresa labora en horario de lunes a viernes de 9:00 A 18:00 horas en todos los departamentos.' }, 
    ])
  }

}
