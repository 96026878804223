import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss']
})

export class LocationMapComponent implements AfterViewInit  {
  
  @ViewChild('mapContainer', {static: false}) gmap: ElementRef;
  public lat = 21.901980157713304
  public lng = -102.28716562699071

  public map: google.maps.Map;
  public marker
  public mapOptions: google.maps.MapOptions
  public coordinates
  public showMap
  public locationIcon = 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fcontact%2Fubicacio%CC%81n%20l.png?alt=media&token=d8512d5f-924b-43ec-abda-a1aaebc30b13'

  constructor() { }

  mapInitializer() {
    this.coordinates = new google.maps.LatLng(this.lat, this.lng);
    this.mapOptions = {
      center: this.coordinates,
      zoom: 17
     };
     try {
      this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
      this.marker = new google.maps.Marker({
        position: this.coordinates,
        map: this.map,
      });
      this.showMap = true
      this.marker.setMap(this.map)
     } catch (error) {
        console.log(error)
        this.showMap = false
     }
  }

  ngAfterViewInit() {
    this.showMap = false
    this.mapInitializer();
  }

  ngOnChanges() {
    this.mapInitializer();
  }

  ngOnInit() {
    
  }

}
