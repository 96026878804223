import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { PermissionGuard } from './guards/permission.guard';
import { UserResolver } from './guards/user.resolver'

import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SolutionsServicesComponent } from './solutions-services/solutions-services.component';
import { StoreComponent } from './store/store.component';
import { LeduUserComponent } from './ledu-user/ledu-user.component';
import { ContactComponent } from './contact/contact.component';
import { UserViewComponent } from './user-view/user-view.component';
import { LoginComponent } from './login/login.component';
import { AdminViewComponent } from './admin-view/admin-view.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { UsersManagerComponent } from './users-manager/users-manager.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { SmProductsComponent } from './sm-products/sm-products.component';
import { CartComponent } from './cart/cart.component';
import { OrderCheckoutComponent } from './order-checkout/order-checkout.component';
import { PlacedOrderComponent } from './placed-order/placed-order.component';
import { OrdersManagerComponent } from './orders-manager/orders-manager.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { ToOnlinePaymentComponent } from './to-online-payment/to-online-payment.component';
import { BlogMainComponent } from './blog-main/blog-main.component';
import { BlogArticleComponent } from './blog-article/blog-article.component';
import { BlogAdminComponent } from './blog-admin/blog-admin.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SiteUnderMaintenanceComponent } from './site-under-maintenance/site-under-maintenance.component';

const routes: Routes = [
  {
    path: '', component: MainComponent, children: [
      { path: '', redirectTo: 'inicio', pathMatch: 'full' },
      { path: 'inicio', component: HomeComponent },
      { path: 'nosotros', component: AboutUsComponent },
      { path: 'soluciones-y-servicios', component: SolutionsServicesComponent },
      // { path: 'tienda', component: StoreComponent },
      // { path: 'producto/:id', component: ProductDetailsComponent },
      // { path: 'pedido/:id', component: OrderDetailsComponent },
      { path: 'usuario-ledu', component: LeduUserComponent },
      { path: 'contacto', component: ContactComponent },
      // { path: 'carrito', component: CartComponent },
      // { path: 'finalizar-pedido', component: OrderCheckoutComponent },
      // { path: 'pedido-realizado', component: PlacedOrderComponent },
      { path: 'pago-en-linea', component: ToOnlinePaymentComponent },
      { path: 'avisodeprivacidad', component: PrivacyPolicyComponent },
      { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
      { path: 'blog', component: BlogMainComponent },
      { path: 'articulo/:id', component: BlogArticleComponent },
      { path: 'promociones', component: PromotionsComponent },
      {
        path: 'panel-de-usuario', component: UserViewComponent, resolve: { user: UserResolver }, children: [
          { path: '', redirectTo: 'admin', pathMatch: 'full' },
          {
            path: 'admin', component: AdminViewComponent, canActivate: [PermissionGuard], children: [
              { path: '', redirectTo: 'inicio', pathMatch: 'full' },
              { path: 'inicio', component: AdminHomeComponent },
              { path: 'usuarios', component: UsersManagerComponent },
              { path: 'blog', component: BlogAdminComponent },
              { path: 'productos', component: SmProductsComponent },
              { path: 'pedidos', component: OrdersManagerComponent },
            ]
          },
        ]
      },
    ],
  },
  { path: '404', component: NotFoundComponent },
  { path: 'sitio-en-mantenimiento', component: SiteUnderMaintenanceComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled", anchorScrolling: 'enabled', scrollOffset: [0, 64]})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
