import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../services/firestore/firestore.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-blog-admin',
  templateUrl: './blog-admin.component.html',
  styleUrls: ['./blog-admin.component.scss']
})
export class BlogAdminComponent implements OnInit {

  public currentView:any = {view: 'main', data: ''}
  public blogs:any

  constructor(
    private firestoreService: FirestoreService,
    private spinner: NgxSpinnerService,
  ) { }

  newArticle() {

  }

  getBlogToEdit(id) {
    return this.blogs.find(p => {
      return p.$id === id
    })
  }

  getActiveBlog() {
    return this.blogs.filter(p => {
      return !p.disabled
    })
  }

  ngOnInit() {
    this.spinner.show();
    this.firestoreService.getCollection('blogs').subscribe(snapshot => {
      this.blogs = []
      snapshot.forEach((data: any) => {
        let user = data.payload.doc.data()
        user.$id = data.payload.doc.id
        this.blogs.push(user)
      })
      this.spinner.hide();
    })
  }


}
