import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/core/main.service';
import { FirestoreService } from '../services/firestore/firestore.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import * as _ from 'lodash'

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  public cart: any = []
  public products: any
  public coupon: string

  public total: number = 0
  public subtotal: number  = 0

  constructor(
    private mainService: MainService,
    private firestoreService: FirestoreService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) { }

  quantityMod(product, quantity) {
    let index = this.cart.indexOf(product)
    this.cart[index].quantity = this.cart[index].quantity + quantity
    if (this.cart[index].quantity < 1) {
      this.cart[index].quantity = 1
    }
    this.mainService.setNewCart(this.cart)
  }

  getSubtotalProduct(product) {
    let index = this.cart.indexOf(product)
    let price = product.sale ? product.discount : product.price
    this.cart[index].subtotal = price * product.quantity
    return price * product.quantity
  }

  removeProduct(product) {
    let index = this.cart.indexOf(product)
    this.cart.splice(index, 1);
    this.mainService.setNewCart(this.cart)
  }

  getSubtotal() {
    this.subtotal = _.sumBy<any>(this.cart, f => {
      return f.subtotal;
    })
    return this.subtotal
  }

  getTotal() {
    this.total = _.sumBy<any>(this.cart, f => {
      return f.subtotal;
    })
    return this.total
  }

  saveProducts() {
    this.mainService.saveProductsToOrder({products: this.cart, subtotal: this.subtotal, total: this.total})
    this.router.navigate(['/finalizar-pedido'])
  }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(true)
    window.scrollTo(0, 0);
    this.cart = this.mainService.currentCart

    if(this.cart) {     
      this.spinner.show();
      this.firestoreService.getCollection('products').subscribe(snapshot => {
        this.products = []
        snapshot.forEach((data: any) => {
          let product = data.payload.doc.data()
          product.$id = data.payload.doc.id
          if (!product.disabled) {
            this.products.push(product)
          }
        })
        this.cart = this.cart.map(p => {
          let product = this.products.find(f => {
            return f.$id === p.$id
          })
          product.quantity = p.quantity
          return product
        })
        this.spinner.hide();
      })
    }
  }

}
