import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'about-us-more-au',
  templateUrl: './about-us-more-au.component.html',
  styleUrls: ['./about-us-more-au.component.scss']
})
export class AboutUsMoreAuComponent implements OnInit {

  public image = "https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fmas%20de%20nosotros.png?alt=media&token=1f08d8a7-053b-47bc-b293-11fdf678e2e0"

  public vision = 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fempresa%20Vision.png?alt=media&token=3cc22415-7cd8-4e51-adf5-a4abc7e66afd'
  public mission = 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fempresa%20Mision.png?alt=media&token=6b6986ff-d800-4c18-b202-c581bdb09ba1'
  public values = 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fabout-us%2Fempresa%20Valores.png?alt=media&token=e1a00dd9-f5ca-4025-a2b1-9559e7b1977d'

  constructor() { }

  getImageStyles() {
    let url = `url(${this.image})`;
    return { 'background-image': url, 'background-position': `50% 50%` }
  }

  ngOnInit() {
  }

}
