import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { FirestoreService } from '../services/firestore/firestore.service'
import swal from 'sweetalert2'

@Component({
  selector: 'company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent implements OnInit {
  public images = [
    {
      src: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/company-gallery%2Fgallery-01.jpg?alt=media&token=30d4dec6-ade9-4954-b97c-e36bd48892a7',
      caption: 'SEGURIDAD QUE SE ADAPTA A CADA ESPACIO',
      thumb: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/company-gallery%2Fgallery-01.jpg?alt=media&token=30d4dec6-ade9-4954-b97c-e36bd48892a7',
    },
    {
      src: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/company-gallery%2Fgallery-02.jpg?alt=media&token=490f3beb-89c0-4ecb-92a3-a01845d38d58',
      name: 'DISFRUTA CUANDO ESTES EN CASA PORQUE UN SISTEMA DE ALARMA LEDU TE PROTEGE',
      thumb: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/company-gallery%2Fgallery-02.jpg?alt=media&token=490f3beb-89c0-4ecb-92a3-a01845d38d58',
    },
    {
      src: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/company-gallery%2Fgallery-03.jpg?alt=media&token=14d34912-93f6-4cc6-925b-d8608e06f71e',
      name: 'SIENTETE SEGURO DONDE ESTES',
      thumb: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/company-gallery%2Fgallery-03.jpg?alt=media&token=14d34912-93f6-4cc6-925b-d8608e06f71e',
    }
  ]
  public mail:string = ''

  constructor(private _lightbox: Lightbox, private firestoreService: FirestoreService,) { }

  getImageStyles(image) {
    let url = `url(${image})`;
    return { 'background-image': url, 'background-position': `50% 50%` }
  }

  openLightbox(index) {
    this._lightbox.open(this.images, index);
  }

  closeLightbox(): void {
    this._lightbox.close();
  }

  saveMail() {
    let data = {
      mail: this.mail
    }
    this.firestoreService.create(data, 'newsletter').then(_ => {
      swal.fire({
        icon: 'success',
        title: "Gracias por suscribirse a nuestro Newsletter.",
        timer: 1500,
        showConfirmButton: false,
      })
      this.mail = ''
    })
  }

  ngOnInit() {
  }

}
