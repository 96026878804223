import { Component, OnInit, Input } from '@angular/core';
import { MainService } from '../services/core/main.service';
import { Router } from '@angular/router';

@Component({
  selector: 'order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss']
})
export class OrderCardComponent implements OnInit {
  
  @Input() order
  @Input() products
  public ordersStatus

  constructor(private mainService:MainService, private router: Router,) {
    this.ordersStatus = mainService.ordersStatus
  }

  openOrder(): void {
    this.router.navigate(['/pedido', this.order.$id]);
  }

  findStatus(key) {
    return this.ordersStatus.find(s => {
      return s.key === key
    }).value
  }

  findProduct(id) {
    return this.products.find(p => {
      return p.$id === id
    })
  }

  ngOnInit() {
  }

}
