import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/core/main.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  public images =[
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/section-headers%2Fsection-about-us-d.jpg?alt=media&token=3813d898-7b9e-4abe-b1ba-653e7a1bd208',
      title: 'NOSOTROS',
    }]

  constructor(private mainService: MainService, private titleService: Title, private metaService: Meta) { }

  get solidTopbar(): boolean {
    return this.mainService.solidTopbar;
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(false)
    this.titleService.setTitle('NOSOTROS - LEDU Vidas Tranquilas');
    this.metaService.addTags([
      { name: 'description', content: 'Somos una empresa 100% hidrocálida con 35 años de experiencia en seguridad' }, 
    ])
  }

}
