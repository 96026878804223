import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MainService } from '../services/core/main.service';
import { FirestoreService } from '../services/firestore/firestore.service';
import { NgxSpinnerService } from "ngx-spinner";
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as _ from 'lodash'
import swal from 'sweetalert2'

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  animations: [
    trigger(
      'sideAnimation',
      [
        transition(
          ':enter',
          [
            style({ transform: 'translateX(-60%)', opacity: 0 }),
            animate('400ms', style({ transform: 'translateX(30%)', 'opacity': 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ transform: 'translateX(30%)', 'opacity': 1 }),
            animate('400ms', style({ transform: 'translateX(-60%)', 'opacity': 0 }))
          ]
        )
      ]
    )
  ]
})
export class OrderDetailsComponent implements OnInit {

  private routeSub: Subscription
  public order: any = {}
  public products: any = []
  public ordersStatus
  public paymentStatus
  public showStatusButtons: boolean = false
  public showPaymentStatusButtons: boolean = false
  public dataLoaded: boolean = false

  constructor(
    private route: ActivatedRoute,
    private firestoreService: FirestoreService,
    private spinner: NgxSpinnerService,
    private mainService: MainService
  ) {
    this.ordersStatus = mainService.ordersStatus
    this.paymentStatus = mainService.paymentStatus
  }

  findStatus(key, type?) {
    if(type === 'payment') {
      return this.paymentStatus.find(s => {
        return s.key === key
      })
    } else {
      return this.ordersStatus.find(s => {
        return s.key === key
      })
    }
  }

  findProduct(id) {
    return this.products.find(p => {
      return p.$id === id
    })
  }

  checkFeature(feature) {
    return this.mainService.checkfeature(feature)
  }

  getStatusBtnsToShow(currentStatus, type?) {
    let array 
    if(type === 'payment') {
      array = this.paymentStatus
    } else {
      array = this.ordersStatus
    }
    let status = array.find(s => {
      return s.key === currentStatus
    })
    let index = array.indexOf(status)
    let statusCopy = _.cloneDeep(array);
    statusCopy.splice(index, 1);
    return statusCopy
  }

  changeStatus(status, type?) {
    let text
    let order
    if(type === 'payment') {
      this.showPaymentStatusButtons = false
      text = 'Pedido'
      order = {
        paymentStatus: status.key
      }
    } else {
      this.showStatusButtons = false
      text = 'Pago'
      order = {
        status: status.key
      }
    }
    swal.fire({
      title: "Cambio de Estatus",
      text: `Se cambiará el estatus del ${text} a ${status.value}`,
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: status.color,
      cancelButtonColor: '#BDC3C7',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    })
      .then((accept) => {
        if (accept.value) {
          this.firestoreService.update(this.order.$id, order, 'orders').then(_ => {
            swal.fire({
              icon: 'success',
              title: "¡Actualización Correcta!",
              text: `Se cambio el estatus satisfactoriamente`,
              timer: 1500,
              showConfirmButton: false,
            })
            if(type === 'payment') {
              this.order.paymentStatus = status.key
            } else {
              this.order.status = status.key
            }
          })
        }
      })
  }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(true)
    window.scrollTo(0, 0);
    this.spinner.show()
    this.routeSub = this.route.params.subscribe(params => {
      this.firestoreService.get(params.id, 'orders').subscribe(doc => {
        let order = doc.data()
        order.$id = doc.id
        this.order = order
        this.firestoreService.getCollection('products').subscribe(snapshot => {
          this.products = []
          snapshot.forEach((data: any) => {
            let product = data.payload.doc.data()
            product.$id = data.payload.doc.id
            this.products.push(product)
          })
          this.spinner.hide();
          this.dataLoaded = true
          console.log(this.order)
        })
      })
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }


}
