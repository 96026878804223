import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import localeMX from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/analytics';
import { environment } from '../environments/environment';
import { UserResolver } from './guards/user.resolver';
import { AuthGuard } from './guards/auth.guard';
import { NgxCarouselModule } from 'ngx-carousel';
import 'hammerjs';
import { NgxSplideModule } from 'ngx-splide';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule} from "@angular/common/http";
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgxSpinnerModule } from 'ngx-spinner';
import { CarouselModule } from "ngx-carousel-lib";
import { CarouselModule as CarouselModuleOwl }   from 'ngx-owl-carousel-o';
import { ContextMenuModule} from 'ngx-contextmenu';
import { LightboxModule } from 'ngx-lightbox';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill'
import { CountoModule }  from 'angular2-counto';
import { CustomMaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { MainComponent } from './main/main.component';
import { MainService } from './services/core/main.service';
import { AuthService } from './services/core/auth.service';
import { PaymentsService } from './services/core/payments.service';
import { StorageService } from './services/core/storage.service';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderImageComponent } from './header-image/header-image.component';
import { HomeAboutUsComponent } from './home-about-us/home-about-us.component';
import { HomeSolutionsComponent } from './home-solutions/home-solutions.component';
import { OurClientsComponent } from './our-clients/our-clients.component';
import { CompanyInfoComponent } from './company-info/company-info.component';
import { HomeCountersComponent } from './home-counters/home-counters.component';
import { AboutUsHwaComponent } from './about-us-hwa/about-us-hwa.component';
import { AboutUsClientsCommentsComponent } from './about-us-clients-comments/about-us-clients-comments.component';
import { AboutUsOurTeamComponent } from './about-us-our-team/about-us-our-team.component';
import { AboutUsMoreAuComponent } from './about-us-more-au/about-us-more-au.component';
import { SolutionsServicesComponent } from './solutions-services/solutions-services.component';
import { StoreComponent } from './store/store.component';
import { LeduUserComponent } from './ledu-user/ledu-user.component';
import { ContactComponent } from './contact/contact.component';
import { ServicesCardsComponent } from './services-cards/services-cards.component';
import { ServicesDetailsComponent } from './services-details/services-details.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { LocationMapComponent } from './location-map/location-map.component';
import { LeduUserMonDataComponent } from './ledu-user-mon-data/ledu-user-mon-data.component';
import { UserViewComponent } from './user-view/user-view.component';
import { UsersManagerComponent } from './users-manager/users-manager.component';
import { LoginComponent } from './login/login.component';
import { AdminViewComponent } from './admin-view/admin-view.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { SmProductsComponent } from './sm-products/sm-products.component';
import { SmProductNewComponent } from './sm-product-new/sm-product-new.component';
import { SmProductMainComponent } from './sm-product-main/sm-product-main.component';
import { DropZoneDirective } from './drop-zone.directive';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { CartComponent } from './cart/cart.component';
import { OrderCheckoutComponent } from './order-checkout/order-checkout.component';
import { PlacedOrderComponent } from './placed-order/placed-order.component';
import { OrdersManagerComponent } from './orders-manager/orders-manager.component';
import { OrderCardComponent } from './order-card/order-card.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { MercadopagoButtonComponent } from './mercadopago-button/mercadopago-button.component';
import { ToOnlinePaymentComponent } from './to-online-payment/to-online-payment.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SendPriceComponent } from './send-price/send-price.component';
import { ContactUsTableComponent } from './contact-us-table/contact-us-table.component';
import { SiteUnderMaintenanceComponent } from './site-under-maintenance/site-under-maintenance.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SolutionsSectorsComponent } from './solutions-sectors/solutions-sectors.component';
import { SolutionsDefinitionsComponent } from './solutions-definitions/solutions-definitions.component';
import { SolutionsAdditionalServicesComponent } from './solutions-additional-services/solutions-additional-services.component';
import { LeduUserConsultComponent } from './ledu-user-consult/ledu-user-consult.component';
import { LeduUserBlemComponent } from './ledu-user-blem/ledu-user-blem.component';
import { BlogMainComponent } from './blog-main/blog-main.component';
import { BlogArticleComponent } from './blog-article/blog-article.component';
import { BlogAdminComponent } from './blog-admin/blog-admin.component';
import { BlogNewComponent } from './blog-new/blog-new.component';
import { BlogAdminMainComponent } from './blog-admin-main/blog-admin-main.component';
import { BlogMainHeaderComponent } from './blog-main-header/blog-main-header.component';
import { BlogMainArticlesComponent } from './blog-main-articles/blog-main-articles.component';
import { BlogArticleBodyComponent } from './blog-article-body/blog-article-body.component';
import { BlogArticleRecommendedComponent } from './blog-article-recommended/blog-article-recommended.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { PromotionsPackagesComponent } from './promotions-packages/promotions-packages.component';

registerLocaleData(localeMX);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    MainComponent,
    TopbarComponent,
    FooterComponent,
    HeaderImageComponent,
    HomeAboutUsComponent,
    HomeSolutionsComponent,
    OurClientsComponent,
    CompanyInfoComponent,
    HomeCountersComponent,
    AboutUsHwaComponent,
    AboutUsClientsCommentsComponent,
    AboutUsOurTeamComponent,
    AboutUsMoreAuComponent,
    SolutionsServicesComponent,
    StoreComponent,
    LeduUserComponent,
    ContactComponent,
    ServicesCardsComponent,
    ServicesDetailsComponent,
    ContactFormComponent,
    ContactInfoComponent,
    LocationMapComponent,
    LeduUserMonDataComponent,
    UserViewComponent,
    UsersManagerComponent,
    LoginComponent,
    AdminViewComponent,
    AdminHomeComponent,
    UserDetailsComponent,
    ProductCardComponent,
    ProductDetailsComponent,
    SmProductsComponent,
    SmProductNewComponent,
    SmProductMainComponent,
    DropZoneDirective,
    CartComponent,
    OrderCheckoutComponent,
    PlacedOrderComponent,
    OrdersManagerComponent,
    OrderCardComponent,
    OrderDetailsComponent,
    MercadopagoButtonComponent,
    ToOnlinePaymentComponent,
    NotFoundComponent,
    SendPriceComponent,
    ContactUsTableComponent,
    SiteUnderMaintenanceComponent,
    PrivacyPolicyComponent,
    SolutionsSectorsComponent,
    SolutionsDefinitionsComponent,
    SolutionsAdditionalServicesComponent,
    LeduUserConsultComponent,
    LeduUserBlemComponent,
    BlogMainComponent,
    BlogArticleComponent,
    BlogAdminComponent,
    BlogNewComponent,
    BlogAdminMainComponent,
    BlogMainHeaderComponent,
    BlogMainArticlesComponent,
    BlogArticleBodyComponent,
    BlogArticleRecommendedComponent,
    PromotionsComponent,
    PromotionsPackagesComponent,
  ],
  entryComponents: [
    ServicesDetailsComponent,
    UserDetailsComponent,
    SendPriceComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    CarouselModule,
    ContextMenuModule.forRoot(),
    LightboxModule,
    QuillModule.forRoot(),
    CountoModule,
    CustomMaterialModule,
    NgbModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    CarouselModuleOwl,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    PinchZoomModule,
    NgxPayPalModule,
    HttpClientModule,
    NgxCarouselModule,
    NgxSplideModule,
    NgxPageScrollCoreModule
  ],
  providers: [
    MainService, 
    StorageService, 
    AuthService, 
    PaymentsService, 
    UserResolver, AuthGuard, 
    ScreenTrackingService, 
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}, 
    {provide: LOCALE_ID, useValue: 'es-MX' },
    {provide: 'googleTagManagerId',  useValue: 'GTM-TS45PWD6'}
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
