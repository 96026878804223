import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/core/main.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private mainService: MainService, private router: Router) { }

  toHome() {
    this.router.navigate(['/inicio'])
    setTimeout
    setTimeout(_ => {
      location.reload();
    }, 1500);
  }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(true)
  }

}
