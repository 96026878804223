import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AngularFireObject } from '@angular/fire/database';

import { AuthService } from '../services/core/auth.service';
import { Observable } from 'rxjs';


@Injectable()
export class UserResolver implements Resolve<Observable<any>> {

  constructor(public authService: AuthService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<any>> {
    return new Promise((resolve, reject) => {
      this.authService.getCurrentUserData()
      .then(res => {
        return resolve(res);
      }, err => {
        this.router.navigate(['/login']);
        return reject(err);
      })
    })
  }
}