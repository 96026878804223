import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/core/main.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-ledu-user',
  templateUrl: './ledu-user.component.html',
  styleUrls: ['./ledu-user.component.scss']
})
export class LeduUserComponent implements OnInit {

  public images = [
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fusers%2Fheader%20users.jpg?alt=media&token=03749767-534c-4657-84ff-cc937adfd9c7',
      title: 'USUARIO',
    }]

  constructor(private mainService: MainService,  private titleService: Title, private metaService: Meta) { }

  get solidTopbar(): boolean {
    return this.mainService.solidTopbar;
  }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(false)
    this.titleService.setTitle('USUARIO - LEDU Vidas Tranquilas');
    this.metaService.addTags([
      { name: 'description', content: 'Descubre todos los beneficios que puedes tener al ser cliente de LEDU. Puedes pagar tus servicios de LEDU sin salir de tu casa desde nuestra plataforma, es 100% seguro' }, 
    ])
  }

}
