import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-site-under-maintenance',
  templateUrl: './site-under-maintenance.component.html',
  styleUrls: ['./site-under-maintenance.component.scss']
})
export class SiteUnderMaintenanceComponent implements OnInit {

  constructor( private router: Router) { }

  toHome() {
    this.router.navigate(['/inicio'])
    setTimeout
    setTimeout(_ => {
      location.reload();
    }, 1500);
  }

  ngOnInit() {
  }

}
