import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/core/main.service';
import { Title, Meta } from '@angular/platform-browser';
import { FirestoreService } from '../services/firestore/firestore.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as _ from 'lodash';

@Component({
  selector: 'app-blog-main',
  templateUrl: './blog-main.component.html',
  styleUrls: ['./blog-main.component.scss']
})
export class BlogMainComponent implements OnInit {

  public blogs = []
  public dataReady = false

  constructor(
    private mainService: MainService,
    private titleService: Title,
    private metaService: Meta,
    private firestoreService: FirestoreService,
    private spinner: NgxSpinnerService,
  ) { }


  get solidTopbar(): boolean {
    return this.mainService.solidTopbar;
  }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(true)
    this.titleService.setTitle('BLOG - LEDU Vidas Tranquilas');
    this.metaService.addTags([
      { name: 'description', content: 'Blog de LEDU' },
    ])

    this.spinner.show()
    this.firestoreService.getCollection('blogs').subscribe(snapshot => {
      this.blogs = []
      snapshot.forEach((data: any) => {
        let user = data.payload.doc.data()
        user.$id = data.payload.doc.id
        this.blogs.push(user)
      })
      this.blogs =  _.orderBy<any>(this.blogs, 'created_at', 'desc')
      this.spinner.hide()
      this.dataReady = true
    })
  }

}