import { Component, OnInit, Inject } from '@angular/core';
import { MainService } from '../services/core/main.service';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import 'rxjs/add/operator/filter';
import { PageScrollService } from 'ngx-page-scroll-core';

@Component({
  selector: 'app-solutions-services',
  templateUrl: './solutions-services.component.html',
  styleUrls: ['./solutions-services.component.scss']
})
export class SolutionsServicesComponent implements OnInit {

  public routeFragment
  public images = [
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fsolutions-services%2Fheader%20soluciones%20y%20servicios.jpg?alt=media&token=e026f5e8-694d-43af-a371-78f52814256a',
      title: 'SOLUCIONES Y SERVICIOS',
    }]

  public sectors = [
    {
      name: 'INDUSTRIAL',
      element: 'industrial',
      image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fsolutions-services%2FIndustrial.png?alt=media&token=c6b70a39-cf6e-4d40-87a1-1961aaa4cf5e',
      badge: 'Seguridad de 360 grados de tecnología',
      badgeExtra: 'Servicios extras de monitoreo para tu industria:',
      extraServices: [
        '- Monitoreo 24/7 los 365 días del año',
        '- Rondines especiales',
        '- Acceso web',
        '- Control de horarios',
        '- Video verificación y revisión de enlaces CCTV',
        '- Reporte de actividades'
      ]
    },
    {
      name: 'COMERCIAL',
      element: 'comercial',
      image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fsolutions-services%2FComercial.png?alt=media&token=98420874-6a0a-4351-a595-98ea78d916d5',
      badge: 'Tranquilidad y seguridad en todo momento',
      badgeExtra: 'Servicios extras de monitoreo para tu negocio:',
      extraServices: [
        '- Monitoreo 24/7 los 365 días del año',
        '- Acceso web',
        '- Control de horarios',
        '- Video verificación y revisión de enlaces CCTV',
        '- Reporte de actividades',
        '- Rondines especiales'
      ]
    },
    {
      name: 'RESIDENCIAL',
      element: 'residencial',
      image: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fsolutions-services%2FResidencial.png?alt=media&token=6faac313-7ba3-4aff-baec-e4e199d42165',
      badge: 'Tranquilidad y seguridad en todo momento',
      badgeExtra: 'Servicios extras de monitoreo para tu hogar:',
      extraServices: [
        '- Monitoreo 24/7 los 365 días del año',
        '- Rondines especiales',
        '- Acceso web',
        '- Aplicación Smartpanics',
        '- Video verificación y revisión de enlaces CCTV',
      ]
    }
  ]


  constructor(
    private mainService: MainService,
    private titleService: Title,
    private metaService: Meta,
    private route: ActivatedRoute,
    private pageScrollService: PageScrollService, @Inject(DOCUMENT)
    private document: any
  ) { }

  get solidTopbar(): boolean {
    return this.mainService.solidTopbar;
  }

  ngAfterViewInit() {
    this.route.fragment.subscribe(f => {
      this.routeFragment = f;
      // document.getElementById(this.routeFragment).scrollIntoView();
      let element = document.getElementById(this.routeFragment);
      if (!element) return
      if (element.getBoundingClientRect() !== null) {

        let offset = 80;

        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        })

      }

    })




  }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(false)
    this.titleService.setTitle('SOLUCIONES Y SERVICIOS - LEDU Vidas Tranquilas');
    this.metaService.addTags([
      { name: 'description', content: 'La tranquilidad que necesitas al alcance de tu mano: Video Vigilancia, GPS, Alarmas, Monitoreo, Controles de Acceso, Alarma VS Incendio' },
    ])
    // this.route.queryParams
    //   .filter(params => params.sector)
    //   .subscribe(params => {
    //     this.dataReady = true
    //     this.pageScrollService.scroll({
    //       document: this.document,
    //       scrollTarget: '#' + params.sector,
    //     })
    //   }
    //   )
    // this.route.fragment.subscribe(f => {
    //   this.routeFragment = f;
    //   console.log(this.routeFragment)
    //   this.pageScrollService.scroll({
    //     document: this.document,
    //     scrollTarget: `.${this.routeFragment}`,
    //   })
    // });
  }

}
