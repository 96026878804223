import { Component, OnInit, Input, SecurityContext  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'header-image',
  templateUrl: './header-image.component.html',
  styleUrls: ['./header-image.component.scss'],
  providers: [NgbCarouselConfig]
})
export class HeaderImageComponent implements OnInit {

  @Input() type
  @Input() gradient
  @Input() images
  @Input() blue
  public show = true
  public objectStyles

  constructor(private carouselConfig: NgbCarouselConfig, private sanitizer: DomSanitizer) {
    carouselConfig.interval = 6000;
    carouselConfig.wrap = true;
    carouselConfig.keyboard = true;
  }

  getImageStyles(image,special) {
    let style
    if(this.blue) {
      style = this.gradient ? "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 65%, rgba(12, 37, 129,1) 100%), " : ""
    } else {
      style = this.gradient ? "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 60%, rgba(117,188,30,0.5) 100%), " : ""
    }
    let url = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(image))
    let gradient = `${style}url(${url})`
    this.objectStyles =  { 'background-image': gradient, 'background-position': `50% 50%` }
    return this.sanitizer.sanitize(SecurityContext.STYLE, this.sanitizer.bypassSecurityTrustStyle(this.objectStyles))
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.show = false
    if(this.type === 'single') {
      this.carouselConfig.showNavigationArrows = false
      this.carouselConfig.showNavigationIndicators = false
      this.show = true
    } else {
      this.show = true
    }
  }

}
