import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-to-online-payment',
  templateUrl: './to-online-payment.component.html',
  styleUrls: ['./to-online-payment.component.scss']
})
export class ToOnlinePaymentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.location.replace(environment.saiECommerceURL);
  }

}
