import { Component, OnInit, Input, SecurityContext  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'home-about-us',
  templateUrl: './home-about-us.component.html',
  styleUrls: ['./home-about-us.component.scss']
})
export class HomeAboutUsComponent implements OnInit {
  
  public images = [
    "https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fvarious%2Fcamara%20LEDU%201.png?alt=media&token=ae30583c-8308-49f6-95ac-f77aca10b2e6",
    "",
    ""
  ]
  public sectors = [{ name:'INDUSTRIAL', element: 'industrial'},{ name: 'COMERCIAL', element: 'comercial'}, { name:'RESIDENCIAL', element: 'residencial'}]
  public objectStyles


  constructor(private sanitizer: DomSanitizer, private router: Router) { }

  // openSector(sector): void {
  //   this.router.navigate(
  //     ['/soluciones-y-servicios'],
  //     { queryParams: { sector: sector.element } }
  //   );
  // }

  getImage() {
    let url = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(this.images[0]))
    return url
  }

  ngOnInit() {
  }

}
