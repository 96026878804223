import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendPriceComponent } from '../send-price/send-price.component';


@Component({
  selector: 'services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss']
})
export class ServicesDetailsComponent implements OnInit {

  @Input() data
  @Input() isService

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) { }

  openSendPrice() {
    const modalRef = this.modalService.open(SendPriceComponent, { size: 'lg', centered: true, keyboard: true, windowClass : "lg"});
    modalRef.componentInstance.data = this.data;
    modalRef.componentInstance.type = this.isService ? 'service' : 'solution';
    this.activeModal.dismiss('Cross click')
  }

  ngOnInit() {
  }

}
