import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FilesUploadService {

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: Observable<string>;

  constructor(private storage: AngularFireStorage) { }

  uploadFile(event: FileList, path: string, onlyImage) {
    return new Promise((resolve, reject) => {
      const file = event.item(0)
      const completePath = `${path}/${moment.now()}_${file.name}`
      if (file.type.split('/')[0] !== 'image' && onlyImage) return 'unsupported file type';
      const fileRef = this.storage.ref(completePath);
      this.task = this.storage.upload(completePath, file)
      this.task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL()
          this.downloadURL.subscribe(url => {
            resolve(url)
          })
        })
      ).subscribe()
    });
  }

  uploadMultipleFiles(file, path: string) {
    return new Promise((resolve, reject) => {
      const completePath = `${path}/${moment.now()}_${file.name}`;
      const fileRef = this.storage.ref(completePath);
      this.task = this.storage.upload(completePath, file)
      this.task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL()
          this.downloadURL.subscribe(url => {
            let data = {
              url,
              name: file.name,
              size: file.size,
              ext: file.name.split('.').pop().toLowerCase(),
              created_at: moment.now(),
            }
            resolve(data)
          })
        })
      ).subscribe()
    });
  }
}
