import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../services/firestore/firestore.service';
import { NgxSpinnerService } from "ngx-spinner";
import { MainService } from '../services/core/main.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {

  public images = [
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/section-headers%2Fheader-store.jpg?alt=media&token=d5c8421a-9c14-4835-8566-15c2f609689c',
      title: 'TIENDA',
    }]

  public products

  constructor(
    private firestoreService: FirestoreService,
    private spinner: NgxSpinnerService,
    private mainService: MainService,
    private titleService: Title, 
    private metaService: Meta
  ) { }

  getActiveProducts() {
    return this.products.filter(p => {
      return !p.disabled
    })
  }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(false)
    this.titleService.setTitle('TIENDA - LEDU Vidas Tranquilas');
    this.metaService.addTags([
      { name: 'description', content: 'Ahora puedes comprar nuestros productos en linea: Kit de Alarma Alámbrico, Sensor de Movimiento Alámbrico, Cámara IP, etc.' }, 
    ])
    this.spinner.show();
    this.firestoreService.getCollection('products').subscribe(snapshot => {
      this.products = []
      snapshot.forEach((data: any) => {
        let product = data.payload.doc.data()
        product.$id = data.payload.doc.id
        if(!product.disabled) {
          this.products.push(product)
        }
      })
      this.spinner.hide();
    })
  }

}
