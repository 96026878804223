import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {

  public adminModules = [
    { key:'usuarios', name: 'Usuarios', text: 'Registra nuevos usuarios que puedan modificar el contenido del sitio', color: '#2D3047', icon: 'fas fa-users' },
    { key:'productos', name: 'Productos', text: 'Altas, bajas y cambios de productos', color: '#75bc1e', icon: 'fas fa-boxes' },
    { key:'pedidos', name: 'Pedidos', text: 'Revisa pedidos activos o finalizados', color: '#a0674b', icon: 'fas fa-file-alt' },
    { key:'blog', name: 'Blog', text: 'Crea nuevos artículos o modifica los que ya existen', color: '#a0674b', icon: 'fas fa-newspaper' },
  ]

  constructor(
    
  ) { }

  ngOnInit() {
  }

}
