import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/core/main.service';
import { StorageService } from '../services/core/storage.service';
import { FirestoreService } from '../services/firestore/firestore.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import * as _ from 'lodash'
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-checkout',
  templateUrl: './order-checkout.component.html',
  styleUrls: ['./order-checkout.component.scss']
})
export class OrderCheckoutComponent implements OnInit {

  public payPalConfig: IPayPalConfig;
  private _orderProducts: any = []
  private _subtotal: any = 0
  private _total: any = 0
  public aditionalInfo: string = ''
  public invoiceInfo: any = {
    country: 'México'
  }
  public formTouched: any = []
  public paymentStatus:any = []
  public states: any = [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Campeche',
    'Chiapas',
    'Chihuahua',
    'Coahuila de Zaragoza',
    'Colima',
    'Ciudad de México',
    'Durango',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco',
    'Estado de Mexico',
    'Michoacan de Ocampo',
    'Morelos',
    'Nayarit',
    'Nuevo Leon',
    'Oaxaca',
    'Puebla',
    'Queretaro de Arteaga',
    'Quintana Roo',
    'San Luis Potosi',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz de Ignacio de la Llave',
    'Yucatan',
    'Zacatecas',
  ];

  public get orderProducts(): any {
    if (this.mainService.dataToOrder)
      return this.mainService.dataToOrder.products || []
    else
      return []
  }
  public get subtotal(): any {
    if (this.mainService.dataToOrder)
      return this.mainService.dataToOrder.subtotal || 0
    else
      return 0
  }
  public get total(): any {
    if (this.mainService.dataToOrder)
      return this.mainService.dataToOrder.total || 0
    else
      return 0
  }

  constructor(
    private mainService: MainService,
    private firestoreService: FirestoreService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private http: HttpClient,
    private storageService: StorageService,
    private route: ActivatedRoute
  ) {
    this.initConfig();
    this.paymentStatus = mainService.paymentStatus
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2
        ? []
        : this.states.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  requiredField(model, field) {
    if (model === '' || model === undefined) {
      let already = this.formTouched.some(f => {
        return f === field
      })
      if (!already) {
        this.formTouched.push(field)
      }
    } else {
      let index = this.formTouched.indexOf(field)
      if (index >= 0) {
        this.formTouched.splice(index, 1)
      }
    }
    this.checkAllFields()
  }

  checkAllFields() {
    let allFields = ['name', 'lastName', 'address', 'city', 'state', 'postalCode', 'phone', 'email']
    allFields.forEach(field => {
      if (this.invoiceInfo[field] === '' || this.invoiceInfo[field] === undefined) {
        let already = this.formTouched.some(f => {
          return f === field
        })
        if (!already) {
          this.formTouched.push(field)
        }
      } else {
        let index = this.formTouched.indexOf(field)
        if (index >= 0) {
          this.formTouched.splice(index, 1)
        }
      }
    })
    this.storageService.set('userInfo', { invoiceInfo: this.invoiceInfo, aditionalInfo: this.aditionalInfo })
  }

  checkToched(field) {
    let touched = this.formTouched.some(f => {
      return f === field
    })
    return touched ? 'touched-required' : ''
  }

  validateData() {
    if (this.invoiceInfo.name && this.invoiceInfo.lastName && this.invoiceInfo.address && this.invoiceInfo.city && this.invoiceInfo.state && this.invoiceInfo.postalCode && this.invoiceInfo.phone && this.invoiceInfo.email) {
      return true
    } else {
      return false
    }
  }

  createOrder(paymentType, aditionalData?) {
    this.spinner.show()
    let products = this.orderProducts.map(p => {
      return { id: p.$id, quantity: p.quantity, subtotal: p.subtotal }
    })
    let order: any = {
      products: products,
      subtotal: this.subtotal,
      total: this.total,
      invoiceInfo: this.invoiceInfo,
      aditionalInfo: this.aditionalInfo,
      created_at: moment.now(),
      status: 'pending',
      payment: paymentType
    }
    order = { ...order, ...aditionalData }
    this.firestoreService.get('general', 'config').subscribe(doc => {
      let config = doc.data()
      order.number = config.ordersSerial + 1
      this.firestoreService.update('general', { ordersSerial: order.number }, 'config').then(_ => {
        this.firestoreService.create(order, 'orders').then(docRef => {
          let id = docRef.id
          let mailBody = {
            type: 'order',
            orderId: id,
            orderNumber: order.number,
            total: order.total,
            name: order.invoiceInfo.name,
            lastName: order.invoiceInfo.lastName,
            address: order.invoiceInfo.address,
            city: order.invoiceInfo.city,
            state: order.invoiceInfo.state,
            postalCode: order.invoiceInfo.postalCode,
            mail: order.invoiceInfo.email,
          }
          this.sendMail(mailBody)
          let otherMail = { ...mailBody }
          otherMail.type = 'order-notif'
          this.sendMail(otherMail)
          this.router.navigate(['/pedido-realizado'])
          this.mainService.toggleSolidTopbar(true)
          console.log(aditionalData)
          this.spinner.hide()
        })
      })
    })

  }


  sendMail(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    this.http.post('https://us-central1-ledu-web-page.cloudfunctions.net/sendNotificationMail', data, httpOptions)
      .subscribe((res: any) => {
        console.log('MAIL SENT')
        console.log(res)
      },
        (err) => {
          console.log(err)
        })
  }

  initConfig() {
    let items = this.orderProducts.map(p => {
      let product = {
        name: p.name,
        quantity: p.quantity,
        category: 'PHYSICAL_GOODS',
        unit_amount: {
          currency_code: 'MXN',
          value: p.sale ? p.discount : p.price,
        }
      }
      return product
    })
    this.payPalConfig = {
      currency: 'MXN',
      clientId: environment.clientId,

      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'MXN',
              value: this.total,
              breakdown: {
                item_total: {
                  currency_code: 'MXN',
                  value: this.total
                }
              }
            },
            items
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        actions.order.get().then(details => {
        });
      },
      onClientAuthorization: (data) => {
        this.createOrder('PAYPAL', { paypalOrder: data, paymentStatus: 'paid' })
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    };
  }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(true)
    window.scrollTo(0, 0);
    if (this.storageService.exist('userInfo')) {
      let userInfo = this.storageService.get('userInfo')
      this.invoiceInfo = userInfo.invoiceInfo
      this.aditionalInfo = userInfo.aditionalInfo
      this.route.queryParams.subscribe(params => {
        let status = params['status']
        let paymentMethod = params['payment_type']
        let merchantOrderId = params['merchant_order_id']
        let paymentId = params['payment_id']
        if (status === 'approved') {
          this.createOrder('MERCADOPAGO', { paymentMethod, merchantOrderId, paymentId, paymentStatus: 'paid' })
        } else if (status === 'pending') {
          this.createOrder('MERCADOPAGO', { paymentMethod, merchantOrderId, paymentId, paymentStatus: 'pending' })
        }
      })
    }
  }

}
