import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/core/main.service';
import { AuthService } from '../services/core/auth.service'
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public email: string
  public password: string
  public error: boolean

  constructor( public authService: AuthService, private router: Router, private mainService: MainService) { }

  tryLogin(){
    this.authService.SignIn(this.email, this.password)
    .then(res => {
      this.router.navigate(['/panel-de-usuario']);
    }).catch( err => {
      console.log(err);
      this.error = true
    })
  }

  get solidTopbar(): boolean {
    return this.mainService.solidTopbar;
  }

  ngOnInit() {
    this.mainService.toggleSolidTopbar(true)
  }


}
