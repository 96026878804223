import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/core/auth.service';
import { MainService } from '../services/core/main.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(private mainService:MainService, private authService: AuthService,  private router: Router) { 
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ): Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.authService.getCurrentUserData()
      .then(user => {
        let access = this.mainService.checkRoleForRoutes(route.routeConfig.path, user)
        if(access) {
          return resolve(true)
        } else {
          this.router.navigate(['/inicio']);
          return resolve(false);
        }
      }, err => {
        this.router.navigate(['/login']);
        return resolve(false);
      })
    })
  }

}
